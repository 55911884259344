import { Autocomplete, TextField, Button, InputAdornment } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { API } from '../Constants'

const options = {
    null: 'Saýlanmadyk',
    property_1: '1-nji aýratyklyk',
    property_2: '2-nji aýratyklyk',
    property_3: '3-nji aýratyklyk',
    property_4: '4-nji aýratyklyk',
    property_5: '5-nji aýratyklyk',
}

const emptySettings = {
    id: 1,
    category_1: null,
    category_2: null,
    discount: null,
    brand: null,
    description: null,
    gadagan: null,
    gadagan_word: "",
    gadagan_price: "",
    baner_spacing: 5,
}

export const Settings = () => {
    const [state, setState] = React.useState(emptySettings)
    const [loading, setLoading] = React.useState(false)
    const [reload, setReload] = React.useState(0)

    React.useState(() => {
        setLoading(true)
        const data = new FormData();
        data.append("data", JSON.stringify({
            type: 'settings'
        }));
        axios.post(API.getData, data, {}).then(res => {
            setLoading(false)
            setState(res.data?.[0] || emptySettings)
        })
    }, [reload])

    const handleSave = () => {
        setLoading(true)
        const data2 = new FormData();
        data2.append("data", JSON.stringify({ data: state, type: 'settings', action: 'SAVE' }))
        axios.post(API.writeData, data2, {}).then(res => {
            setLoading(false)
            setReload(reload => reload + 1)
        }).catch(console.error)
    }

    return (
        <div style={{ flexGrow: 1 }}>
            <div style={{ margin: 10 }}>
                <Autocomplete
                    fullWidth
                    options={Object.keys(options)}
                    getOptionLabel={option => options[option]}
                    size='small'
                    disabled={loading}
                    onChange={(e, newValue) => setState(data => ({ ...state, category_1: newValue }))}
                    value={state.category_1 || 'null'}
                    renderInput={props => <TextField label='Esasy kategoriýa' {...props} />}
                />
            </div>
            <div style={{ margin: 10 }}>
                <Autocomplete
                    fullWidth
                    options={Object.keys(options)}
                    getOptionLabel={option => options[option]}
                    size='small'
                    disabled={loading}
                    onChange={(e, newValue) => setState(data => ({ ...state, category_2: newValue }))}
                    value={state.category_2 || 'null'}
                    renderInput={props => <TextField label='Içki kategoriýa' {...props} />}
                />
            </div>
            <div style={{ margin: 10 }}>
                <Autocomplete
                    fullWidth
                    options={Object.keys(options)}
                    getOptionLabel={option => options[option]}
                    size='small'
                    disabled={loading}
                    onChange={(e, newValue) => setState(data => ({ ...state, brand: newValue }))}
                    value={state.brand || 'null'}
                    renderInput={props => <TextField label='Brend' {...props} />}
                />
            </div>
            <div style={{ margin: 10 }}>
                <Autocomplete
                    fullWidth
                    options={Object.keys(options)}
                    getOptionLabel={option => options[option]}
                    size='small'
                    disabled={loading}
                    onChange={(e, newValue) => setState(data => ({ ...state, discount: newValue }))}
                    value={state.discount || 'null'}
                    renderInput={props => <TextField label='Arzanladyş' {...props} />}
                />
            </div>
            <div style={{ margin: 10 }}>
                <Autocomplete
                    fullWidth
                    options={Object.keys(options)}
                    getOptionLabel={option => options[option]}
                    size='small'
                    disabled={loading}
                    onChange={(e, newValue) => setState(data => ({ ...state, description: newValue }))}
                    value={state.description || 'null'}
                    renderInput={props => <TextField label='Esasy maglumat' {...props} />}
                />
            </div>
            <div style={{ margin: 10, display: 'flex', alignItems: 'center' }}>
                <Autocomplete
                    fullWidth
                    options={Object.keys(options)}
                    getOptionLabel={option => options[option]}
                    size='small'
                    disabled={loading}
                    onChange={(e, newValue) => setState(data => ({ ...state, gadagan: newValue }))}
                    value={state.gadagan || 'null'}
                    renderInput={props => <TextField label='Gizleniljek harytlaryň aýratynlygy' {...props} />}
                />
                <TextField
                    fullWidth
                    label='Gizleniljek haryt sözi'
                    size='small'
                    disabled={loading}
                    onChange={e => setState(data => ({ ...data, gadagan_word: e.target.value }))}
                    value={state.gadagan_word}
                    InputProps={{
                        startAdornment: <InputAdornment position='start'>=</InputAdornment>
                    }}
                />
                <TextField
                    fullWidth
                    label='Gizleniljek baha sözi'
                    size='small'
                    disabled={loading}
                    onChange={e => setState(data => ({ ...data, gadagan_price: e.target.value }))}
                    value={state.gadagan_price}
                    InputProps={{
                        startAdornment: <InputAdornment position='start'>=</InputAdornment>
                    }}
                />
            </div>
            <div style={{ margin: 10 }}>
                <TextField
                    fullWidth
                    label='Baner her näçe kategoriýadan soň bolsun'
                    size='small'
                    type='number'
                    disabled={loading}
                    onChange={e => setState(data => ({ ...data, baner_spacing: e.target.value }))}
                    value={state.baner_spacing}
                />
            </div>
            <div style={{ margin: 10, textAlign: 'right' }}>
                <Button disabled={loading} onClick={handleSave} size='small' variant='contained'>Ýatda sakla</Button>
            </div>
        </div>
    )
}