const API_URL = 'https://owadan.000webhostapp.com/'
// const API_URL = 'http://developer/aish_htttp/'


export const API = {
    DATAS: API_URL + 'get/?action=initialdatas',
    PRODUCT: API_URL + 'get/?action=productdatas',
    FILES: API_URL + 'json/?id=',
    MULTIPLE_FILES: API_URL + 'get/?action=uploadmultiplefiles',
    home: API_URL + 'get/?action=home',
    authCache: API_URL + 'get/?action=authcache',
    signIn: API_URL + 'get/?action=signin',
    signOut: API_URL + 'get/?action=signout',
    getProducts: API_URL + 'get/?action=getproducts',
    filterProducts: API_URL + 'get/?action=filterproducts',
    newData: API_URL + 'get/?action=newdata',
    fileAction: API_URL + 'get/?action=files',
    uploadImage: API_URL + 'get/?action=uploadimage',
    getData: API_URL + 'get/?action=data',
    getCartData: API_URL + 'get/?action=getcartdata',
    getCartDataSubmit: API_URL + 'get/?action=getcartdatasubmit',
    writeData: API_URL + 'get/?action=writedata',
    acceptCart: API_URL + 'get/?action=acceptcart',
    clearData: API_URL + 'get/?action=clear',
    savePrice: API_URL + 'get/?action=saveprice',
}
