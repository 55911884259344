import React from 'react'
import { Avatar, Divider, Tabs, Tab, Card, Button, Typography, Slider, IconButton, Collapse, FormControl, FormHelperText, TextField, CircularProgress, Grid } from '@mui/material';
import { useSelector, useDispatch, connect } from 'react-redux';
import { AddOutlined, CategorySharp, CheckOutlined, RemoveOutlined, SearchOffOutlined, SortOutlined } from '@mui/icons-material';
import { Menu, Dropdown } from 'antd';
import axios from 'axios';
import { API } from '../Constants';
import { NavLink } from 'react-router-dom';

export const SearchPage = () => {
    const [open, setOpen] = React.useState(false)

    const categories = useSelector(state => state.DATAS.categories)
    const defValues = useSelector(state => state.DATAS.defValues)

    const { subCategories, category_2, category_1, stateValue, totalCount } = useSelector(state => {
        const { subCategories, category_2, category_1, price, totalCount } = state.SEARCH
        return { subCategories, category_2, category_1, stateValue: price, totalCount }
    })

    const dispatch = useDispatch()
    const handleClick = (category) => {
        if (category?.id >= 0) {
            dispatch({ type: 'REDUCER/SEARCH/CATEGORY_1', category_1: category.name, subCategories: category.subCategories, category_2: '' })
        } else {
            dispatch({ type: 'REDUCER/SEARCH/CATEGORY_2', category_2: category })
        }
    }

    const handleSort = (order) => {
        dispatch({ type: 'REDUCER/SEARCH/ORDER', order })
    }

    const menu = (
        <Menu>
            <Menu.Item key={0} onClick={() => handleSort('')} >Adaty</Menu.Item>
            <Menu.Item key={0} onClick={() => handleSort('p.name ASC')} >Ady boýunça A-Z</Menu.Item>
            <Menu.Item key={0} onClick={() => handleSort('p.name DESC')} >Ady boýunça Z-A</Menu.Item>
            <Menu.Item key={1} onClick={() => handleSort('price ASC')} >Arzandan gymmada</Menu.Item>
            <Menu.Item key={1} onClick={() => handleSort('price DESC')} >Gymmatdan arzana</Menu.Item>
        </Menu>
    )
    const getPopupContainerHandler = (e) => {
        return document.getElementById('ant-design') || document.body
    }
    return (
        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', background: 'white', paddingLeft: 20, paddingRight: 20, overflow: 'hidden' }}>
            <Card style={{ display: 'flex', margin: 2, marginTop: 10, paddingTop: 16, paddingBottom: 16, paddingLeft: 8, paddingRight: 8, alignItems: 'center' }} elevation={3}>
                <IconButton onClick={() => setOpen(open => !open)} size='small'><SearchOffOutlined fontSize='small' /></IconButton>
                <Typography variant='caption'>Tapylan {totalCount}</Typography>
                <div style={{ flexGrow: 1 }} />
                <Dropdown getPopupContainer={getPopupContainerHandler} overlay={menu} placement="bottomLeft">
                    <IconButton size='small'><SortOutlined fontSize='small' /></IconButton>
                </Dropdown>
            </Card>
            <Tabs
                variant="scrollable"
                scrollButtons
                style={{ padding: 0, margin: 0 }}
                value={[{ name: "", subCategories: [], id: 0 }, ...categories].find(c => c.name === category_1)?.id}>
                {[{ name: "", subCategories: [], id: 0 }, ...categories].map((category, idx) => <Tab value={category.id} onClick={() => handleClick(category)} key={idx} label={<div style={{ display: 'flex', alignItems: 'center' }}>
                    {category.id > 0 && <Avatar style={{ marginRight: 8, height: 20, width: 20 }} src={encodeURI(category.image)}><CategorySharp /></Avatar>}
                    <span>{category.name || 'Ählisi'}</span>
                </div>} />)}
            </Tabs>
            {subCategories.length > 0 && <Tabs
                variant="scrollable"
                scrollButtons
                style={{ padding: 0, margin: 0 }}
                value={category_2}>
                <Tab value={""} onClick={() => handleClick("")} style={{ padding: 5, minHeight: 0 }} label={<Typography variant='caption'>Ählisi</Typography>} />
                {subCategories.map((category, idx) => <Tab style={{ padding: 5, margin: 0, minHeight: 0 }} value={category} onClick={() => handleClick(category)} key={idx} label={<Typography variant='caption'>{category}</Typography>} />)}
            </Tabs>}
            <Collapse in={open}>
                <div style={{ background: 'white', marginTop: 10, padding: 4 }}>
                    <PriceSlider stateValue={stateValue} defValues={defValues} />
                </div>
            </Collapse>
            <Divider />
            <Products />
        </div>
    )
}

const Products = connect(state => state.SEARCH)(props => {
    const [loading, setLoading] = React.useState(false)
    const dispatch = useDispatch()

    React.useEffect(() => {
        setLoading(true)
        let timer = null
        const getData = () => {
            const filterData = { page: props.page * 100 }
            if (props.text.length > 0) filterData.name = props.text
            if (props.category_1.length > 0) filterData.category_1 = props.category_1
            if (props.category_2.length > 0) filterData.category_2 = props.category_2
            if (props.order.length > 0) filterData.order = props.order
            if (props.price.min > 0) filterData.min = props.price.min
            if (props.price.max > 0) filterData.max = props.price.max
            const data = new FormData();
            data.append("data", JSON.stringify(filterData));
            axios.post(API.filterProducts, data, {}).then(res => {
                dispatch({ type: 'REDUCER/SEARCH/PRODUCTS', products: res.data?.products || [], totalCount: res.data?.totalCount || 0, page: props.page })
                setLoading(false)
            }).catch(console.error)
        }
        timer = setTimeout(getData, 1000)
        return () => timer && clearTimeout(timer)
    }, [props.text, props.category_1, props.category_2, props.price, props.order, props.page, dispatch])
    const setPage = () => {
        dispatch({ type: 'REDUCER/SEARCH/PRODUCTS-PAGE' })
    }
    return (
        <div style={{ overflow: 'auto', flexGrow: 1, paddingTop: 20, paddingBottom: 10 }}>
            <Grid container style={{ marginBottom: 20 }}>
                {props.products.map(product => <Product key={product.id} product={product} />)}
            </Grid>
            {loading ? <CircularProgress size={20} /> : props.totalCount > props.products.length ? <Button onClick={setPage} variant='contained' size='small'>Ýene</Button> : null}
        </div>
    )
})

const Product = ({ product }) => {
    const count = useSelector(state => state.CART.products?.[product.id] || 0)
    const dispatch = useDispatch()
    const handleAddData = () => {
        dispatch({
            type: 'REDUCER/DATAS/PRODUCT_DATA',
            id: product.id,
            data: product
        })
    }

    const handleCart = count => {
        dispatch({
            type: 'REDUCER/CART/CHANGE_COUNT',
            count,
            id: product.id
        })
    }
    return (
        <Grid
            item
            xs={6}
            sm={4}
            lg={3}
            alignContent='justify'
            alignItems='justify'
        >
            <Card elevation={4} style={{ margin: 4, height: 'calc(100% - 4px)', display: 'flex' }}>
                <div style={{ padding: 8, display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
                    <NavLink onClick={handleAddData} to={`/product-details/${product.id}`}><div className='image-view' style={{ height: 120, backgroundImage: `url(${encodeURI(product.image)})`, marginBottom: 4 }} /></NavLink>
                    <Divider />
                    <div style={{ textAlign: 'right' }}><Typography variant='caption' style={{ fontSize: 9, lineHeight: 1 }}>{product.code}</Typography></div>
                    <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
                        <NavLink onClick={handleAddData} to={`/product-details/${product.id}`}><Typography variant='caption'>{product.name}</Typography></NavLink>
                        <div style={{ flexGrow: 1 }} />
                        <NavLink onClick={handleAddData} to={`/product-details/${product.id}`}><Typography variant='subtitle1' color='primary'>{`${parseFloat(product.price).toFixed(2)} TMT`}</Typography></NavLink>
                    </div>
                    <div style={{ flexGrow: 1 }}><NavLink onClick={handleAddData} to={`/product-details/${product.id}`}><Typography variant='caption'>{product.description}</Typography></NavLink></div>
                    {count > 0 ? <div style={{ display: 'flex' }}>
                        <Button onClick={() => handleCart(count - 1)} size='small' variant='contained' style={{ minWidth: 0 }}><RemoveOutlined fontSize='small' /></Button>
                        <Typography variant='caption' style={{ margin: 4, flexGrow: 1, textAlign: 'center' }}>{count}</Typography>
                        <Button onClick={() => handleCart(count + 1)} size='small' variant='contained' style={{ minWidth: 0 }}><AddOutlined fontSize='small' /></Button>
                    </div> : <Button onClick={() => handleCart(1)} size='small' variant='contained'>Sebede goş</Button>}
                </div>
            </Card>
        </Grid>
    )
}

const PriceSlider = ({ stateValue, defValues }) => {
    const [value, setValue] = React.useState([stateValue.min, stateValue.max])
    const dispatch = useDispatch()

    React.useEffect(() => {
        setValue([stateValue.min, stateValue.max])
    }, [stateValue])

    const handleChange = (event, value, activeThumb) => {
        setValue(value)
    }

    const handleInputChange = (val, idx) => {
        if (parseInt(val) < defValues.min || parseInt(val) > defValues.max) return

        setValue(value => [
            idx === 0 ? parseInt(val) : value[0],
            idx === 0 ? value[1] : parseInt(val)
        ])
    }

    const handleSubmit = () => {
        dispatch({
            type: 'REDUCER/SEARCH/PRICE',
            price: {
                min: value[0],
                max: value[1]
            }
        })
    }
    return (
        <FormControl fullWidth>
            <FormHelperText style={{ margin: 0, display: 'flex', alignItems: 'center' }} component="legend">
                Bahasy
                <div style={{ flexGrow: 1 }} />
                {(value[0] !== stateValue.min || value[1] !== stateValue.max) && <IconButton onClick={handleSubmit} size='small'><CheckOutlined fontSize='small' color='primary' /></IconButton>}
            </FormHelperText>
            <Slider
                value={[value[0], value[1] || defValues.max]}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                style={{ marginLeft: 20, marginRight: 20, marginTop: 10, width: 'calc(100% - 40px)' }}
                max={defValues.max}
                min={defValues.min}
                onChange={handleChange}
                step={1}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <MyInput
                    onChange={(event) => handleInputChange(event.target.value || '0', 0)}
                    value={value[0] === defValues.min ? 0 : value[0]}
                    placeholder='Min'
                />
                <div style={{ flexGrow: 1 }}> - </div>
                <MyInput
                    onChange={(event) => handleInputChange(event.target.value || '0', 1)}
                    value={value[1] === defValues.max ? 0 : value[1] || defValues.max}
                    placeholder='Max'
                />
            </div>
        </FormControl>
    )
}


const MyInput = (props) => {

    return (
        <TextField
            size='small'
            fullWidth
            variant='standard'
            inputProps={{ style: { textAlign: 'center', minWidth: 0 } }}
            value={props.value === 0 ? '' : props.value}
            onChange={props.onChange}
            placeholder={props.placeholder}
        />
    )
}