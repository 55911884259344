const initialState = {
    categories: [],
    brands: [],
    products: {},
    defValues: {
        min: 0, max: 0
    }
}

export const DatasReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'REDUCER/DATAS/DATAS':
            return {
                ...state,
                categories: action.categories || [],
                brands: action.brands || [],
                defValues: {
                    min: parseFloat(action.defValues.min),
                    max: parseFloat(action.defValues.max)
                }
            }
        case 'REDUCER/DATAS/PRODUCT_DATA':
            return {
                ...state,
                products: {
                    ...state.products,
                    [action.id]: action.data
                }
            }
        default:
            return state
    }
}