import React from 'react'
import PropTypes from 'prop-types';
import { words } from './Dictionary'

const LanguageContext = React.createContext(null);


const initialState = localStorage.getItem('language') ? {
  ...JSON.parse(localStorage.getItem('language')),
  words
} :
{
  lng: 'tm',
  words
}

const LanguageProvider = ({ state = initialState, children }) => {

  const [lng, setLang] = React.useState(state.lng);
  const [words] = React.useState(state.words)

  const translate = (word, lngProps) => {
    if((word || '').toString().length === 0){
      return word
    }

    const wordFound = words.find(w => w.tm === word)
    
    if(wordFound){
      return wordFound[lngProps || lng] || word
    }else{
    //     setTimeout(() => setWords(words => {
          const wordsNew = [
                                ...words,
                                {
                                  tm: word,
                                  ru: '',
                                  en: ''
                                }
                            ]
          const initialState = {
            words: wordsNew,
            lng
          }

          localStorage.setItem('language', JSON.stringify(initialState))
          
    //       let myWords = localStorage.getItem('wordsNew') ? JSON.parse(localStorage.getItem('wordsNew')) : []
    //       myWords = [
    //         ...myWords.filter(w => w.tm !== word),
    //         {
    //           tm: word,
    //           ru: '',
    //           en: ''
    //         }
    //       ]
    //       localStorage.setItem('wordsNew', JSON.stringify(myWords))
    //       return wordsNew
    //   }), 200)
      return word
    }
  }

  const setLanguage = lng => {
    const initialState = {
      words,
      lng
    }
    localStorage.setItem('language', JSON.stringify(initialState))
    setLang(lng)
  }

  return (
    <LanguageContext.Provider value={[{ Translate: translate, translate, setLanguage, lng }, null]}>
      {children}
    </LanguageContext.Provider>
  )
}

export const useLanguage = () => {
  return React.useContext(LanguageContext);
}


export const withLanguage = component => {
  class WrappedComponent extends React.Component{
      shouldComponentUpdate(nextProps){
          try{
            return JSON.stringify(nextProps.state) !== JSON.stringify(this.props.state)
          }catch{
            return true
          }
      }
      render(){
          return <this.props.component {...this.props.state} />
      }
  }
  return props => 
      <LanguageContext.Consumer>
          {([language]) => {
              return <WrappedComponent component={component} state={{...language, ...props}} />
          }}
      </LanguageContext.Consumer>
}


export const Translate = ({ children, ...props }) => {

  const [{ translate, lng }] = useLanguage()
  try{
    return (
      <>{translate(children, props.lng || lng)}</>
    )
  }catch(e){
    (
      <>{children}</>
    )
  }
}

export const TranslateText = ({text = '', texts = [], Component, lng}) => {
  const [{ translate }] = useLanguage()
  return <Component text={translate(text, lng)} texts={texts.map(text => translate(text, lng))} />
}

export const TranslateOwnWords = (props) => {
  const [{ lng }] = useLanguage()
  return props.string ? props[lng] : (
    <>{props[lng] || props.tm}</>
  )
}

TranslateOwnWords.propTypes = {
  tm: PropTypes.string,
  en: PropTypes.string,
  ru: PropTypes.string,
  string: PropTypes.bool
}

TranslateOwnWords.defaultProps = {
  string: false
}

export default function LanguageWrapper(props) {
  return (
    <LanguageProvider>
        {props.children}
    </LanguageProvider>
  )
}