import React from 'react'
import { Table, TableHead, TableRow, Typography, TableCell, TableBody, IconButton, Button, Dialog, DialogContent, DialogActions, TextField, TableContainer, TablePagination, InputAdornment, CircularProgress, List, ListItem, ListItemSecondaryAction, DialogTitle, ListItemIcon, ListItemText, Avatar } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, SortOutlined, SearchOutlined, CheckOutlined, DeleteOutline, Category } from '@mui/icons-material';
import axios from 'axios';
import { API } from '../Constants';
import { useDispatch, connect, useSelector } from 'react-redux';


const data_keys = {
    status: 'Status',
    name: 'Ady',
    phone: 'Telefon belgisi',
    note: 'Bellik',
    sum: 'Jemi'
}

export const CartSettings = () => {
    const id = useSelector(state => state.AUTH?.user?.id)
    const [state, setState] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [users, setusers] = React.useState([])
    const [selected, setSelected] = React.useState(null)
    const [datas, setDatas] = React.useState({
        users: [],
        page: 0,
        countPerPage: 10,
        totalCount: 0,
        order: 'name',
        orderType: 'ASC',
        loading: true
    })

    React.useEffect(() => {
        setLoading(true)
        axios.get(API.getCartData+`&seller=${id}`).then(res => {
            setLoading(false)
            setusers(res.data)
        })
    }, [id])


    React.useEffect(() => {
        setDatas(datas => ({
            ...datas,
            users: users.filter(user =>
                user.name.toLowerCase().includes((state?.name || '').toLowerCase()) &&
                user.phone.toLowerCase().includes((state?.phone || '').toLowerCase()) &&
                user.note.toLowerCase().includes((state?.note || '').toLowerCase())
            )
        }))
    }, [state, users])

    const handleSubmit = user => {
        const data = new FormData();
        data.append("data", JSON.stringify({
            id: user.id,
            action: 'submit'
        }));
        axios.post(API.getCartDataSubmit, data, {}).then(res => {
            setusers(users => users.map(u => u.id === user.id ? ({ ...user, status: 'Tassyklanan' }) : u))
            setSelected(null)
        })
    }

    const handleDelete = user => {
        const data = new FormData();
        data.append("data", JSON.stringify({
            id: user.id,
            action: 'delete'
        }))
        axios.post(API.getCartDataSubmit, data, {}).then(res => {
            setusers(users => users.filter(u => u.id !== user.id))
            setSelected(null)
        })
    }

    return (
        <TableContainer style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', paddingTop: 10 }}>
            <Table stickyHeader size='small' style={{ flexGrow: 1 }}>
                <TableHead>
                    <TableRow>
                        {Object.keys(data_keys).map((key, idx) => <TableCell style={{ padding: 3 }} key={key}>
                            {idx < 4 ? <TextField
                                fullWidth
                                size='small'
                                value={state[key] || ''}
                                onChange={e => setState(state => ({ ...state, [key]: e.target.value }))}
                                label={data_keys[key]}
                                InputProps={{
                                    startAdornment: <InputAdornment position='start'>
                                        <IconButton size='small' onClick={() => setDatas(datas => ({ ...datas, order: key, orderType: datas.order === key && datas.orderType === 'ASC' ? 'DESC' : 'ASC' }))}>
                                            {key !== datas.order ? <SortOutlined fontSize='small' /> : datas.orderType === 'ASC' ? <KeyboardArrowDown fontSize='small' /> : <KeyboardArrowUp fontSize='small' />}
                                        </IconButton>
                                    </InputAdornment>
                                }} /> : data_keys[key]}
                        </TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? <TableRow style={{ height: '100%' }}><TableCell align='center' colSpan={5}><CircularProgress size={40} /></TableCell></TableRow>
                        : datas.users.slice(datas.countPerPage * datas.page, datas.countPerPage * (datas.page + 1)).sort((a, b) => a[datas.order].toLowerCase() > b[datas.order].toLowerCase() ? datas.orderType === 'ASC' ? 1 : -1 : datas.orderType === 'ASC' ? -1 : 1 - 1).map(user => <TableRow key={user.id}>
                            <TableCell>{user.status}</TableCell>
                            <TableCell>{user.name}</TableCell>
                            <TableCell>{user.phone}</TableCell>
                            <TableCell>{user.note}</TableCell>
                            <TableCell>{`${parseFloat(user.sum)} TMT`}</TableCell>
                            <TableCell align='center' width={90}>
                                <div style={{ display: 'flex' }}>
                                    <IconButton onClick={() => setSelected(user)} size='small'><SearchOutlined fontSize='small' /></IconButton>
                                    {user.status === 'Garaşylýar' ? <IconButton onClick={() => handleSubmit(user)} size='small'><CheckOutlined style={{ fill: 'green' }} fontSize='small' /></IconButton>
                                        : <IconButton onClick={() => handleDelete(user)} size='small'><DeleteOutline style={{ color: 'red' }} fontSize='small' /></IconButton>}
                                </div>
                            </TableCell>
                        </TableRow>)}
                    <TableRow style={{ height: '100%' }} />
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={datas.users.length}
                page={datas.page}
                rowsPerPage={datas.countPerPage}
                onPageChange={(e, page) => setDatas(datas => ({ ...datas, page }))}
                onRowsPerPageChange={e => setDatas(datas => ({ ...datas, page: 0, countPerPage: parseInt(e.target.value, 10) }))}
            />
            {selected && <Adduser data={selected} setData={setSelected} handleClose={() => setSelected(null)} handleSubmit={handleSubmit} handleDelete={handleDelete} />}
        </TableContainer>
    )
}

const Adduser = ({ data, handleClose, setData, handleSubmit, handleDelete }) => {


    return (
        <Dialog
            open
            maxWidth='lg'
            onClose={handleClose}>
            <DialogTitle>
                <div style={{ lineHeight: 1 }}>{data.name}</div>
                <div style={{ lineHeight: 1 }}><Typography variant='caption'>{data.phone}</Typography></div>
                <div style={{ lineHeight: 1 }}><Typography variant='caption'>{data.note}</Typography></div>
            </DialogTitle>
            <DialogContent style={{ minWidth: 350 }}>
                <List style={{ flexGrow: 1, background: 'white' }}>
                    {data.products.map(product => <Product key={product.id} {...product} />)}
                    <ListItem><ListItemSecondaryAction>{`JEMI : ${data.sum} TMT`}</ListItemSecondaryAction></ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={() => handleDelete(data)} size='small'><DeleteOutline style={{ color: 'red' }} fontSize='small' /></Button>
                <Button disabled={data.status === 'Tassyklanan'} variant='outlined' onClick={() => handleSubmit(data)} size='small'><CheckOutlined style={{ fill: 'green' }} fontSize='small' /></Button>
            </DialogActions>
        </Dialog>
    )
}

const Product = connect((state, props) => ({ product: state.DATAS.products?.[props.id] || { id: props.id, notFound: true, name: '...', image: '' } }))(({ id, count, price, product }) => {
    const [loading, setLoading] = React.useState(false)
    const dispatch = useDispatch()
    React.useEffect(() => {
        if (product.notFound) {
            setLoading(true)
            axios.get(`${API.PRODUCT}&id=${product.id}`).then(res => {
                setLoading(false)
                dispatch({
                    type: 'REDUCER/DATAS/PRODUCT_DATA',
                    id: res.data.id,
                    data: res.data
                })
            }).catch(e => {
                setLoading(false)
                console.error(e)
            })
        }
    }, [product.id, product.notFound, dispatch])
    return (
        <ListItem divider>
            {loading ? <CircularProgress /> : <>
                <ListItemIcon><Avatar src={product.image}><Category /></Avatar></ListItemIcon>
                <ListItemText style={{ marginRight: 90 }}>
                    <div>{product.name}</div>
                    <div><Typography variant='caption' style={{ fontSize: 9, lineHeight: 1 }}>{product.code}</Typography></div>
                    <div>
                        <Typography variant='caption' >{`${parseFloat(price)} TMT  x ${count} ${product.measure}`}</Typography>
                    </div>
                </ListItemText>
                <ListItemSecondaryAction>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ margin: 4, textAlign: 'center', minWidth: 30, display: 'inline-block' }}><Typography variant='caption' >
                            {`${parseFloat(price) * parseFloat(count)} TMT`}
                        </Typography></div>
                    </div>
                </ListItemSecondaryAction>
            </>}
        </ListItem>
    )
})