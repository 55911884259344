export const words = [
    {
        "tm": "Ady",
        "ru": "Имя",
        "en": "Name"
    },
    {
        "tm": "Gözleg…",
        "ru": "Поиск...",
        "en": "Search..."
    },
    {
        "tm": "Ählisi",
        "ru": "Все",
        "en": "All"
    },
    {
        "tm": "Goş",
        "ru": "Добавить",
        "en": "Add"
    },
    {
        "tm": "Ýene",
        "ru": "Еще",
        "en": "Next"
    },
    {
        "tm": "Maglumat",
        "ru": "Информация",
        "en": "Information"
    },
    {
        "tm": "Meňzeş harytlar",
        "ru": "Похожие",
        "en": "Related products"
    },
    {
        "tm": "Aýyr",
        "ru": "Убрать",
        "en": "Remove"
    },
    {
        "tm": "Jemi",
        "ru": "Итого",
        "en": "Sum"
    },
    {
        "tm": "Sargyt et",
        "ru": "Заказать",
        "en": "To order"
    },
    {
        "tm": "Adyňyz",
        "ru": "Имя",
        "en": "Name"
    },
    {
        "tm": "Telefon belgiňiz",
        "ru": "Номер телефона",
        "en": "Phone number"
    },
    {
        "tm": "Email salgyňyz",
        "ru": "Адрес электронной почты",
        "en": "Email address"
    },
    {
        "tm": "Bu siziň ähli täzeliklerden habarly bolmagyňyza kömek eder.",
        "ru": "Поможет Вам быть в курсе всех новостей",
        "en": "Helps you keep up to date with all the news"
    },
    {
        "tm": "Has takyk salgy",
        "ru": "Точный адрес",
        "en": "Exact address"
    },
    {
        "tm": "Sargytlary iň amatly usulda gowşurmagymyz üçin kömek eder.",
        "ru": "Поможет нам доставить Ваши заявки самым удобным способом",
        "en": "Help us deliver your requests in the most convenient way"
    },
    {
        "tm": "Ulgama gir",
        "ru": "Войти",
        "en": "Sign In"
    },
    {
        "tm": "Welaýat",
        "ru": "Велаят",
        "en": "Velayat"
    },
    {
        "tm": "sany",
        "ru": "шт",
        "en": "pc"
    },
    {
        "tm": "Harytlar",
        "ru": "Товары",
        "en": "Products"
    },
    {
        tm: 'Aşgabat',
        ru: 'Ашгабат',
        en: 'Ashgabat'
    },
    {
        tm: 'Balkan',
        ru: 'Балкан',
        en: 'Balkan'
    },
    {
        tm: 'Ahal',
        ru: 'Ахал',
        en: 'Ahal'
    },
    {
        tm: 'Mary',
        ru: 'Мары',
        en: 'Mary'
    },
    {
        tm: 'Lebap',
        ru: 'Лебап',
        en: 'Lebap'
    },
    {
        tm: 'Daşoguz',
        ru: 'Дашогуз',
        en: 'Dashoguz'
    },
    {
        tm: 'Arçabil etraby',
        ru: 'Арчабиль этрап',
        en: 'Archabil etrap'
    },
    {
        tm: 'Bagtyýarlyk etraby',
        ru: 'Багтыарлык этрап',
        en: 'Bagtyyarlyk etrap'
    },
    {
        tm: 'Berkararlyk etraby',
        ru: 'Беркарарлык этрап',
        en: 'Berkararlyk etrap'
    },
    {
        tm: 'Çandybil etraby',
        ru: 'Чандибиль этрап',
        en: 'Chandybil etrap'
    },
    {
        tm: 'Köpetdag etraby',
        ru: 'Кепетдаг этрап',
        en: 'Kopetdag etrap'
    },
    {
        tm: 'Bereket etraby',
        ru: 'Берекет этрап',
        en: 'Bereket etrap'
    },
    {
        tm: 'Esenguly etraby',
        ru: 'Эсенгули этрап',
        en: 'Esenguly etrap'
    },
    {
        tm: 'Etrek etraby',
        ru: 'Этрек этрап',
        en: 'Etrek etrap'
    },
    {
        tm: 'Magtymguly etraby',
        ru: 'Магтымгули этрап',
        en: 'Magtymguly etrap'
    },
    {
        tm: 'Serdar etraby',
        ru: 'Сердар этрап',
        en: 'Serdar etrap'
    },
    {
        tm: 'Türkmenbaşy etraby',
        ru: 'Туркменбаши этрап',
        en: 'Turkmenbashy etrap'
    },
    {
        tm: 'Balkanabat ',
        ru: 'Балканабат',
        en: 'Balkanabat etrap'
    },
    {
        tm: 'Bereket ',
        ru: 'Берекет',
        en: 'Bereket'
    },
    {
        tm: 'Garabogaz ',
        ru: 'Гарабогаз',
        en: 'Garabogaz'
    },
    {
        tm: 'Gumdag ',
        ru: 'Гумдаг',
        en: 'Gumdag'
    },
    {
        tm: 'Hazar ',
        ru: 'Хазар',
        en: 'Hazar'
    },
    {
        tm: 'Serdar ',
        ru: 'Сердар',
        en: 'Serdar'
    },
    {
        tm: 'Türkmenbaşy',
        ru: 'Туркменбаши',
        en: 'Turkmenbashy'
    },
    {
        tm: 'Altyn-Asyr etraby',
        ru: 'Алтын-Асыр этрап',
        en: ''
    },
    {
        tm: 'Ak-Bugdaý etraby',
        ru: 'Ак-бугдай этрап',
        en: 'Ak-bugday etrap'
    },
    {
        tm: 'Babadaýhan etraby',
        ru: 'Бабадайхан этрап',
        en: 'Babadayhan etrap'
    },
    {
        tm: 'Baharly etraby',
        ru: 'Бахарлы этрап',
        en: 'Baharly etrap'
    },
    {
        tm: 'Gökdepe etraby',
        ru: 'Гекдепе этрап',
        en: 'Gokdepe etrap'
    },
    {
        tm: 'Kaka etraby',
        ru: 'Кака этрап',
        en: 'Kaka etrap'
    },
    {
        tm: 'Derweze etraby',
        ru: 'Дервезе этрап',
        en: 'Derveze etrap'
    },
    {
        tm: 'Sarahs etraby',
        ru: 'Сарахс этрап',
        en: 'Sarahs etrap'
    },
    {
        tm: 'Tejen etraby',
        ru: 'Теджен этрап',
        en: 'Tejen etrap'
    },
    {
        tm: 'Änew',
        ru: 'Анев',
        en: 'Anev'
    },
    {
        tm: 'Abadan',
        ru: 'Абадан',
        en: 'Abadan'
    },
    {
        tm: 'Tejen',
        ru: 'Теджен',
        en: 'Tejen'
    },
    {
        tm: 'Baharly',
        ru: 'Бахарли',
        en: 'Baharly'
    },
    {
        tm: 'Gökdepe',
        ru: 'Гекдепе',
        en: 'Gokdepe'
    },
    {
        tm: 'Altyn sähra etraby',
        ru: 'Алтын сяхра этрап',
        en: 'Altyn sahra etrap'
    },
    {
        tm: 'Baýramaly etraby',
        ru: 'Байрамали этрап',
        en: 'Bayramaly etrap'
    },
    {
        tm: 'Garagum etraby',
        ru: 'Гарагум этрап',
        en: 'Garagum etrap'
    },
    {
        tm: 'Mary etraby',
        ru: 'Мары этрап',
        en: 'Mary etrap'
    },
    {
        tm: 'Murgap etraby',
        ru: 'Мургап этрап',
        en: 'Murgap etrap'
    },
    {
        tm: 'Oguz Han etraby',
        ru: 'Огуз Хан этрап',
        en: 'Oguz Han etrap'
    },
    {
        tm: 'Sakarçäge etraby',
        ru: 'Сакарчаге этрап',
        en: 'Sakarcage etrap'
    },
    {
        tm: 'Serhetabat etraby',
        ru: 'Серхетабат этрап',
        en: 'Serhetabat etrap'
    },
    {
        tm: 'Tagtabazar etraby',
        ru: 'Тагтабазар этрап',
        en: 'Tagtabazar etrap'
    },
    {
        tm: 'Türkmengala etraby',
        ru: 'Туркменгала этрап',
        en: 'Turkmengala etrap'
    },
    {
        tm: 'Wekilbazar etraby',
        ru: 'Векильбазар этрап',
        en: 'Wekilbazar etrap'
    },
    {
        tm: 'Ýolöten etraby',
        ru: 'Ёлётен этрап',
        en: 'Yolotan etrap'
    },
    {
        tm: 'Mary',
        ru: 'Мары',
        en: 'Mary'
    },
    {
        tm: 'Baýramaly',
        ru: 'Байрамалы',
        en: 'Bayramaly'
    },
    {
        tm: 'Serhetabat',
        ru: 'Серхетабат',
        en: 'Serhetabat'
    },
    {
        tm: 'Şatlyk',
        ru: 'Шатлык',
        en: 'Shatlyk'
    },
    {
        tm: 'Ýolöten',
        ru: 'Ёлётен',
        en: 'Yolotan'
    },
    {
        tm: 'Atamyrat etraby',
        ru: 'Атамырат этрап',
        en: 'Atamyrat etrap'
    },
    {
        tm: 'Beýik Türkmenbaşy etraby',
        ru: 'Бейик Туркменбаши этрап',
        en: 'Beyik Türkmenbashy etrap'
    },
    {
        tm: 'Birata etraby',
        ru: 'Бирата этрап',
        en: 'Birata etrap'
    },
    {
        tm: 'Döwletli etraby',
        ru: 'Довлетли этрап',
        en: 'Dowletli etrap'
    },
    {
        tm: 'Farap etraby',
        ru: 'Фарап этрап',
        en: 'Farap etrap'
    },
    {
        tm: 'Galkynyş etraby',
        ru: 'Галкыныш этрап',
        en: 'Galkynysh etrap'
    },
    {
        tm: 'Garabekewül etraby',
        ru: 'Гарабекевюль этрап',
        en: 'Garabekewul etrap'
    },
    {
        tm: 'Garaşsyzlyk etraby',
        ru: 'Гарашзылык этрап',
        en: 'Garashsyzlyk etrap'
    },
    {
        tm: 'Halaç etraby',
        ru: 'Халач этрап',
        en: 'Halac etrap'
    },
    {
        tm: 'Hojambaz etraby',
        ru: 'Ходжамбаз этрап',
        en: 'Hojambaz etrap'
    },
    {
        tm: 'Köýtendag etraby',
        ru: 'Койтендаг этрап',
        en: 'Koytendag etrap'
    },
    {
        tm: 'Magdanly etraby',
        ru: 'Магданлы этрап',
        en: 'Magdanly etrap'
    },
    {
        tm: 'Sakar etraby',
        ru: 'Сакар этрап',
        en: 'Sakar etrap'
    },
    {
        tm: 'Saýat etraby',
        ru: 'Саят этрап',
        en: 'Sayat etrap'
    },
    {
        tm: 'Serdarabat etraby',
        ru: 'Сердарабат этрап',
        en: 'Serdarabat etrap'
    },
    {
        tm: 'Seýdi etraby',
        ru: 'Сейди этрап',
        en: 'Seydi etrap'
    },
    {
        tm: 'Atamyrat',
        ru: 'Атамырат',
        en: 'Atamyrat'
    },
    {
        tm: 'Gazojak',
        ru: 'Газожак',
        en: 'Gazojak'
    },
    {
        tm: 'Magdanly ',
        ru: 'Магданлы',
        en: 'Magdanly'
    },
    {
        tm: 'Seýdi',
        ru: 'Сейди',
        en: 'Seydi'
    },
    {
        tm: 'Türkmenabat',
        ru: 'Туркменабат',
        en: 'Turkmenabat'
    },
    {
        tm: 'Akdepe etraby',
        ru: 'Акдепе этрап',
        en: 'Akdepe etrap'
    },
    {
        tm: 'Boldumsaz etraby',
        ru: 'Болдумсаз этрап',
        en: 'Boldumsaz etrap'
    },
    {
        tm: 'Görogly etraby',
        ru: 'Гёрогли этрап',
        en: 'Gorogly etrap'
    },
    {
        tm: 'Gubadag etraby',
        ru: 'Губадаг этрап',
        en: 'Gubadag etrap'
    },
    {
        tm: 'Gurbansoltan Eje etraby',
        ru: 'Гурбансолтан эже этрап',
        en: 'Gurbansoltan Eje etrap'
    },
    {
        tm: 'Köneürgenç etraby',
        ru: 'Конеургенч etrap',
        en: 'Koneurgenc etrap'
    },
    {
        tm: 'Ruhybelent etraby',
        ru: 'Рухыбелент этрап',
        en: 'Ruhybelent etrap'
    },
    {
        tm: 'Saparmyrat Nyýazow adyndaky etrap',
        ru: 'Этрап имени Сапармират Ниязова',
        en: 'Saparmyrat Nyyazov etrap'
    },
    {
        tm: 'Saparmyrat Türkmenbaşy adyndaky etrap',
        ru: 'Этрап имени Сапармират Туркменбаши',
        en: 'Saparmyrat Turkmenbashy etrap'
    },
    {
        tm: 'Daşoguz',
        ru: 'Дашогуз',
        en: 'Dashoguz'
    },
    {
        tm: 'Köneürgenç',
        ru: 'Конеургенч',
        en: 'Koneurgenc'
    },
    {
        "tm": "Etrap / Şäher / Şäherçe",
        "ru": "Етрап / Город / Городок",
        "en": "Region / City"
    },
    {
        "tm": "Üýtget",
        "ru": "Изменить",
        "en": "Change"
    },
    {
        "tm": "Ulgamdan çyk",
        "ru": "Выйти",
        "en": "Sign out"
    },
    {
        "tm": "Siz ulgamda",
        "ru": "Зарегистрирован",
        "en": "Registered"
    },
    {
        "tm": "Siz hakykatdan hem ulgamdan çykmak isleýäňizmi?",
        "ru": "Вы действительно хотите выйти?",
        "en": "Do you really want to sign out?"
    },
    {
        "tm": "Saýlanmadyk",
        "ru": "Не выбрано",
        "en": "Not selected"
    },
    {
        "tm": "Ady boýunça A-Z",
        "ru": "Сортировать по имени А-Я",
        "en": "Sort by name A-Z"
    },
    {
        "tm": "Ady boýunça Z-A",
        "ru": "Сортировать по имени Я-А",
        "en": "Sort by name Z-A"
    },
    {
        "tm": "Arzandan gymmada",
        "ru": "По возрастанию цены",
        "en": "Price low to hight"
    },
    {
        "tm": "Gymmatdan arzana",
        "ru": "По убыванию цены",
        "en": "Price hight to low"
    },
    {
        "tm": "Tapylan",
        "ru": "Найдено",
        "en": "Found"
    },{
        "tm": "Harytlary deňeşdir:",
        "ru": "Сравнивать товары",
        "en": "Compare products"
    },
    {
        "tm": "Giňişleýin gözleg",
        "ru": "Глобальный поиск",
        "en": "Global search"
    },
    {
        "tm": "Bahasy",
        "ru": "Цена",
        "en": "Price"
    },
    {
        "tm": "Kategoriýalar",
        "ru": "Категории",
        "en": "Categories"
    },
    {
        "tm": "Parameterler",
        "ru": "Параметры",
        "en": "Parameters"
    },
    {
        "tm": "Arassala",
        "ru": "Очистить",
        "en": "Clear"
    },
    {
        "tm": "Gözleg",
        "ru": "Поиск",
        "en": "Search"
    },
    {
        "tm": "Deňeşdir",
        "ru": "Сравнивать",
        "en": "Compare"
    },
    {
        "tm": "Halanlarym",
        "ru": "Избранные",
        "en": "Favorites"
    },
    {
        "tm": "Tapylmady!",
        "ru": "Не найдено",
        "en": "Not found"
    },
    {
        "tm": "Şuwagt al",
        "ru": "Купить сейчас",
        "en": "Buy now"
    },
    {
        "tm": "Satyn al",
        "ru": "Купить",
        "en": "Buy"
    },
    {
        "tm": "Sargytlar",
        "ru": "Все заказы",
        "en": "Orders"
    },
    {
        "tm": "ATOM20 Internet söwda",
        "ru": "ATOM20 Интернет магазин",
        "en": "ATOM20 Online shopping"
    },
    {
        "tm": "Sebediňiz boş!",
        "ru": "Корзина пуста",
        "en": "Cart is empty"
    },
    {
        "tm": "Halanlarym boş!",
        "ru": "Избранные пуста",
        "en": "Favorites is empty"
    },
    {
        "tm": "Profil",
        "ru": "Профил",
        "en": "Profile"
    },
    {
        "tm": "Gözlenenler",
        "ru": "История поиска",
        "en": "Searched"
    },
    {
        "tm": "Garaşylýar...",
        "ru": "Ожидание...",
        "en": "Waiting..."
    },
    {
        "tm": "Ýolda...",
        "ru": "В пути...",
        "en": "On the way..."
    },
    {
        "tm": "Sargytlar tapylmady",
        "ru": "Заказов не найдено",
        "en": "No orders found"
    },
    {
        "tm": "Ýagdaýy",
        "ru": "Статус",
        "en": "Status"
    },
    {
        "tm": "Sene",
        "ru": "Дата",
        "en": "Date"
    },
    {
        "tm": "Siziň sargydyňyz üstünlikli ýatda saklanydy. Bizden söwda edeniňiz üçin köp sag boluň!",
        "ru": "Ваш заказ успешно принят. Спасибо за покупку!",
        "en": "Your order has been successfully accepted. Thank you for your purchase!"
    },
    {
        "tm": "Kärdeşlerimiz siziň bilen iň gysga wagtyň dowamynda habarlaşar we sargydyňyzy iň amatly usulda gowşurar.",
        "ru": "Наши сотрудники в ближайшем времени свяжется с вами и предоставят ваш заказ самым удобным способом.",
        "en": "Our staff will contact you in the near future and provide your order in the most convenient way."
    },
    {
        "tm": "Telefon belgi",
        "ru": "Номер телефона",
        "en": "Phone number"
    },
    {
        "tm": "Ýüklenilýär...",
        "ru": "Готовиться...",
        "en": "Preparing..."
    },
    {
        "tm": "Meňzeş gözlegler",
        "ru": "Похожие запросы",
        "en": "Related searches"
    },
    {
        "tm": "Filter",
        "ru": "Фильтр",
        "en": "Filter"
    },
    {
        "tm": "Min",
        "ru": "Мин",
        "en": "Min"
    },
    {
        "tm": "Gizle",
        "ru": "Скрыть",
        "en": "Hide"
    },
    {
        "tm": "Görkez",
        "ru": "Показать",
        "en": "Show"
    },
    {
        "tm": "Max",
        "ru": "Макс",
        "en": "Max"
    },
    {
        "tm": "Sebet",
        "ru": "Корзина",
        "en": "Cart"
    },
    {
        "tm": "Söwda dowam",
        "ru": "Продолжить покупки",
        "en": "Continue shopping"
    },
    {
        "tm": "Ähli harytlar",
        "ru": "Все товары",
        "en": "All products"
    },
    {
        "tm": "Bar",
        "ru": "В наличии",
        "en": "In stock"
    },
    {
        "tm": "Galyndy",
        "ru": "Остаток",
        "en": "Remains"
    }
]