import { Avatar, Button, Stack, Typography, Card, Divider } from '@mui/material'
import axios from 'axios'
import React from 'react'
import ReactImageGallery from 'react-image-gallery';
import { API } from './../Constants';
import CategoryIcon from '@mui/icons-material/CategoryOutlined';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { AddOutlined, RemoveOutlined } from '@mui/icons-material';

export const HomePage = () => {

    const [data, setData] = React.useState([])

    React.useEffect(() => {
        let timeOut = null
        const getData = () => {
            axios.get(API.home).then((resp) => {
                setData(resp.data)
            }).catch((e) => {
                timeOut = setTimeout(getData, 3000)
            })
        }
        getData();
        return () => timeOut && clearTimeout(timeOut)
    }, [setData])

    return (
        <Stack direction="column" spacing={2} style={{ flexGrow: 1, overflow: 'hidden' }}>
            {data.map(layout => layout.type === 'BANER' ? <Baner key={layout.key} data={layout.data} /> : <CategoryLayout key={layout.key} data={layout.data} />)}
        </Stack>
    )
}

const Baner = ({ data }) => {
    return (
        <div onMouseDown={e => e.preventDefault()} onTouchStart={e => e.stopPropagation()}>
            <ReactImageGallery
                items={data.images.map(original => ({ original, thumbnail: original }))}
                autoPlay
                showPlayButton={false}
                showFullscreenButton={false}
                showThumbnails={false}
                infinite
                renderItem={ImageItem}
            />
        </div>
    )
}

const ImageItem = (props) => {
    return (
        <div style={{ position: 'relative' }}>
            <div className='image-view' style={{ height: 200, backgroundImage: `url(${encodeURI(props.thumbnail)})`, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />
            <div className='image-view' style={{ height: 200, backgroundImage: `url(${encodeURI(props.original)})` }} />
        </div>
    )
}

const CategoryLayout = ({ data }) => {
    const dispatch = useDispatch()
    const handleAll = () => {
        dispatch({ type: 'REDUCER/SEARCH/CATEGORY_1', category_1: data.name, subCategories: data.subCategories, category_2: '' })
        const tab = 1
        localStorage.setItem('tab', tab)
        dispatch({ type: 'REDUCER/MAIN/TAB_INDEX', tab })
    }

    return data.products.length > 0 ? (
        <div style={{ padding: '5px 10px', overflow: 'hidden' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar style={{ margin: 5, height: 30, width: 30 }} src={data.image} ><CategoryIcon fontSize='small' /></Avatar>
                <Typography variant='caption'>
                    {data.name}
                </Typography>
                <div style={{ flexGrow: 1 }} />
                <Button onClick={handleAll} size='small' color='inherit' >
                    <Typography variant='caption'>
                        Ählisi
                    </Typography>
                </Button>
            </div>
            <div style={{ overflow: 'hidden', width: '100%' }}>
                <div className='horizontal-scroll'>
                    {data.products.map(product =>
                        <Product key={product.id} product={product} />
                    )}

                </div>
            </div>
        </div>
    ) : null
}


const Product = ({ product }) => {
    const count = useSelector(state => state.CART.products?.[product.id] || 0)
    const dispatch = useDispatch()
    const handleAddData = () => {
        dispatch({
            type: 'REDUCER/DATAS/PRODUCT_DATA',
            id: product.id,
            data: product
        })
    }

    const handleCart = count => {
        dispatch({
            type: 'REDUCER/CART/CHANGE_COUNT',
            count,
            id: product.id
        })
    }
    return (
        <Card elevation={4} style={{ margin: 4, display: 'flex', minWidth: 160 }}>
            <div style={{ padding: 8, display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
                <NavLink onClick={handleAddData} to={`/product-details/${product.id}`}><div className='image-view' style={{ height: 120, backgroundImage: `url(${encodeURI(product.image)})`, marginBottom: 4 }} /></NavLink>
                <Divider />
                <div style={{ textAlign: 'right' }}><Typography variant='caption' style={{ fontSize: 9, lineHeight: 1 }}>{product.code}</Typography></div>
                <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
                    <NavLink onClick={handleAddData} to={`/product-details/${product.id}`}><Typography variant='caption' style={{ lineHeight: 1 }}>{product.name}</Typography></NavLink>
                    <div style={{ flexGrow: 1 }} />
                    <NavLink onClick={handleAddData} to={`/product-details/${product.id}`}><Typography variant='subtitle1' color='primary'>{`${parseFloat(product.price).toFixed(2)} TMT`}</Typography></NavLink>
                </div>
                <div style={{ flexGrow: 1 }}><NavLink onClick={handleAddData} to={`/product-details/${product.id}`}><Typography variant='caption'>{product.description}</Typography></NavLink></div>
                {count > 0 ? <div style={{ display: 'flex' }}>
                    <Button onClick={() => handleCart(count - 1)} size='small' variant='contained' style={{ minWidth: 0 }}><RemoveOutlined fontSize='small' /></Button>
                    <Typography variant='caption' style={{ margin: 4, flexGrow: 1, textAlign: 'center' }}>{count}</Typography>
                    <Button onClick={() => handleCart(count + 1)} size='small' variant='contained' style={{ minWidth: 0 }}><AddOutlined fontSize='small' /></Button>
                </div> : <Button onClick={() => handleCart(1)} size='small' variant='contained'>Sebede goş</Button>}
            </div>
        </Card>
    )
}