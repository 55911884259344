import React from 'react'
import { Tab, Tabs } from '@mui/material';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import CartIcon from '@mui/icons-material/ShoppingCartOutlined';
import SearchIcon from '@mui/icons-material/Search';
import PersonPinIcon from '@mui/icons-material/PersonPinOutlined';
import { useSelector, useDispatch } from 'react-redux';
import { CartCountBadge } from './CartCountBadge';

export const Footer = () => {
    const value = useSelector(state => state.TAB)
    const dispatch = useDispatch()

    const handleChange = (event, tab) => {
        localStorage.setItem('tab', tab)
        dispatch({ type: 'REDUCER/MAIN/TAB_INDEX', tab })
    }
    return (
        <footer style={{ position: 'sticky', bottom: 0, background: 'white', zIndex: 1499, overflowX: 'hidden', boxShadow: '0 -1px 1px gray' }}>
            <Tabs value={value} onChange={handleChange} aria-label="icon tabs" variant='fullWidth' >
                <Tab icon={<HomeIcon />} aria-label="phone" />
                <Tab icon={<SearchIcon />} aria-label="favorite" />
                <Tab icon={<CartCountBadge><CartIcon /></CartCountBadge>} aria-label="cart" />
                <Tab icon={<PersonPinIcon />} aria-label="person" />
            </Tabs>
        </footer>
    )
}

