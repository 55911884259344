import React from 'react'
import { CircularProgress, List, ListItem, Typography, ListItemIcon, Button, Avatar, ListItemText, ListItemSecondaryAction, Dialog, DialogTitle, DialogContent, DialogActions, TextField, InputAdornment, IconButton } from '@mui/material';
import { useSelector, connect, useDispatch } from 'react-redux';
import axios from 'axios';
import { API } from '../Constants';
import { AddOutlined, Category, RemoveOutlined, PersonOutline } from '@mui/icons-material';

export const CartPage = () => {
    const { products, client, phone, note } = useSelector(state => state.CART)
    const sum = useSelector(state => Object.keys(state.CART.products).reduce((res, id) => res + (parseFloat(state.DATAS.products[id]?.price || '0') * state.CART.products[id]), 0))

    const [open, setOpen] = React.useState(false)
    return (
        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            {Object.keys(products).length > 0 ? <>
                <List style={{ flexGrow: 1, background: 'white' }}>
                    {Object.keys(products).map(id => products[id] > 0 ? <Product key={id} id={id} count={products[id]} /> : null)}
                    <ListItem><ListItemSecondaryAction>{`JEMI : ${sum} TMT`}</ListItemSecondaryAction></ListItem>
                </List>
                <Button onClick={() => setOpen(true)} color='primary' variant='contained' style={{ margin: 10 }}>Sargyt et</Button>
            </> :
                <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant='h5'>Sebediňiz boş!</Typography>
                </div>}
            <CartClientData open={open} handleClose={() => setOpen(false)} client={client} phone={phone} note={note} sum={sum} products={products} />
        </div>
    )
}

const Product = connect((state, props) => ({ product: state.DATAS.products?.[props.id] || { id: props.id, notFound: true, name: '...', image: '' } }))(({ id, count, product }) => {
    const [loading, setLoading] = React.useState(false)
    const dispatch = useDispatch()
    const handleCart = count => {
        dispatch({
            type: 'REDUCER/CART/CHANGE_COUNT',
            count,
            id: product.id
        })
    }
    React.useEffect(() => {
        if (product.notFound) {
            setLoading(true)
            axios.get(`${API.PRODUCT}&id=${product.id}`).then(res => {
                setLoading(false)
                dispatch({
                    type: 'REDUCER/DATAS/PRODUCT_DATA',
                    id: res.data.id,
                    data: res.data
                })
            }).catch(e => {
                setLoading(false)
                console.error(e)
            })
        }
    }, [product.id, product.notFound, dispatch])
    return (
        <ListItem divider>
            {loading ? <CircularProgress size={20} /> : <>
                <ListItemIcon><Avatar src={product.image}><Category /></Avatar></ListItemIcon>
                <ListItemText style={{ marginRight: 90 }}>
                    <div><Typography variant='caption' style={{ lineHeight: 1 }}>{product.name}</Typography></div>
                    <div>
                        <Typography variant='caption' >{`${parseFloat(product.price)} TMT  x ${count} ${product.measure}`}</Typography>
                    </div>
                </ListItemText>
                <ListItemSecondaryAction>
                    <div style={{ textAlign: 'right' }}><Typography variant='caption' style={{ fontSize: 9, lineHeight: 1 }}>{product.code}</Typography></div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button onClick={() => handleCart(count - 1)} size='small' variant='contained' style={{ minWidth: 0, padding: 3 }}><RemoveOutlined fontSize='small' /></Button>
                        <div style={{ margin: 4, textAlign: 'center', minWidth: 30, display: 'inline-block' }}><Typography variant='caption' >{count}</Typography></div>
                        <Button onClick={() => handleCart(count + 1)} size='small' variant='contained' style={{ minWidth: 0, padding: 3 }}><AddOutlined fontSize='small' /></Button>
                    </div>
                </ListItemSecondaryAction>
            </>}
        </ListItem>
    )
})

const CartClientData = ({ open, handleClose, sum, client, phone, note, products }) => {
    const [saved, setSaved] = React.useState(false)
    const auth = useSelector(state => state.AUTH)
    const dispatch = useDispatch()
    const handleSelect = () => {
        dispatch({
            type: 'REDUCER/CART/CHANGE_USER_DATA',
            data: {
                client: auth.user.name,
                phone: auth.user.phone || '',
                note: auth.user.note || '',
            }
        })
    }

    const handleChange = key => e => {
        dispatch({
            type: 'REDUCER/CART/CHANGE_USER_DATA',
            data: {
                [key]: e.target.value
            }
        })
    }

    const handleSubmit = () => {
        if (client.length === 0 || phone.length === 0) return
        const data = new FormData();
        data.append("data", JSON.stringify({
            client,
            phone,
            note,
            products: JSON.stringify(products)
        }));
        axios.post(API.acceptCart, data, {}).then(res => {
            setSaved(true)
            dispatch({
                type: 'REDUCER/CART/CLEAR_SAVED'
            })
        })
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth='sm'>
            <DialogTitle>
                {saved ? 'Sargyt hasaba alyndy' : 'Sargyt'}
            </DialogTitle>
            {saved ? <DialogContent>
                Sargydyňyz üstünlikli hasaba alyndy. Operatorymyz siziň bilen ýakyn wagtda habarlaşar.
            </DialogContent> :
                <DialogContent>
                    <div style={{ marginBottom: 10 }}>
                        <TextField
                            fullWidth
                            size='small'
                            label='Müşderiniň ady'
                            onChange={handleChange('client')}
                            value={client}
                            InputProps={
                                auth?.user?.name ? {
                                    endAdornment: <InputAdornment onClick={handleSelect} position='end'><IconButton size='small'><PersonOutline fontSize='small' /></IconButton></InputAdornment>
                                } : {}
                            } />
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <TextField
                            fullWidth
                            size='small'
                            label='Telefon belgisi'
                            onChange={handleChange('phone')}
                            value={phone} />
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            size='small'
                            label='Bellik'
                            onChange={handleChange('note')}
                            value={note} />
                    </div>
                </DialogContent>}
            {saved ?
                <DialogActions>
                    <Button onClick={() => { setSaved(false); handleClose() }} size='small' color='primary' variant='contained'>OK</Button>
                </DialogActions>
                :
                <DialogActions>
                    <div style={{ marginRight: 20 }}>{`${'Jemi'} ${sum} TMT`}</div>
                    <Button onClick={handleSubmit} size='small' color='primary' variant='contained'>Sargyt et</Button>
                </DialogActions>}
        </Dialog>
    )
}