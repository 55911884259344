import { TextField, Button, Card, Avatar, InputAdornment, IconButton } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { API } from '../Constants'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux';
import { ExitToAppOutlined, PersonOutline, PhoneOutlined, SettingsOutlined } from '@mui/icons-material';
import { NavLink } from 'react-router-dom'

export const ProfilePage = () => {
    const [state, setState] = React.useState({
        login: '',
        password: '',
        loading: false,
        error: ''
    })
    const auth = useSelector(state => state.AUTH)
    const dispatch = useDispatch()
    const handleSubmit = () => {
        if (state.login.length === 0 || state.password.length === 0) return
        setState(state => ({
            ...state,
            loading: true,
            error: ''
        }))
        const data = new FormData();
        data.append("data", JSON.stringify({
            login: state.login,
            password: state.password,
            session: uuidv4()
        }));
        axios.post(API.signIn, data, {}).then((res) => {
            if (res.data?.session) {
                setState({
                    login: '',
                    password: '',
                    loading: false,
                    error: ''
                })
                localStorage.setItem('auth-session', JSON.stringify(res.data))
                dispatch({
                    type: 'REDUCER/AUTH/DATA',
                    data: res.data
                })
            } else {
                setState(state => ({
                    ...state,
                    error: 'Ulanyjy ady ýa-da gizlin kody nädogry!'
                }))
            }
            setState(state => ({
                ...state,
                loading: false
            }))
        }).catch(e => {
            setState(state => ({
                ...state,
                loading: false,
                error: 'Serwere baglanmak başartmady!'
            }))
        })
    }
    const handleSignOut = () => {
        const data = new FormData();
        data.append("data", JSON.stringify({
            session: auth?.session
        }))
        axios.post(API.signOut, data, {})
        localStorage.setItem('auth-session', JSON.stringify({}))
        dispatch({
            type: 'REDUCER/AUTH/DATA',
            data: {}
        })
    }
    return (
        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: 10 }}>
            <Card elevation={3} style={{ position: 'relative', width: '90%', maxWidth: 450, padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Avatar variant='rounded' style={{ width: 100, height: 100, marginTop: 10, marginBottom: 20 }}><PersonOutline style={{ fontSize: 90 }} /></Avatar>
                {!auth?.user?.name ? <>
                    <TextField
                        fullWidth
                        size='small'
                        label='LogIn'
                        style={{ margin: 5 }}
                        value={state.login}
                        error={Boolean(state.error)}
                        helperText={state.error}
                        onChange={e => setState(state => ({ ...state, error: '', login: e.target.value }))}
                        disabled={state.loading} />
                    <TextField
                        fullWidth
                        size='small'
                        label='Gizlin koduňyz'
                        type='password'
                        style={{ margin: 5 }}
                        value={state.password}
                        error={Boolean(state.error)}
                        helperText={state.error}
                        onChange={e => setState(state => ({ ...state, error: '', password: e.target.value }))}
                        disabled={state.loading} />
                    <Button onClick={handleSubmit} size='small' disabled={state.loading} style={{ marginRight: 5, marginLeft: 'auto', marginTop: 5 }}>Ulgama gir</Button>
                </> : <>
                    <TextField
                        fullWidth
                        size='small'
                        label='LogIn'
                        style={{ margin: 5 }}
                        value={auth.user.name}
                        disabled
                        InputProps={{
                            startAdornment: <InputAdornment position='start'><PersonOutline /></InputAdornment>
                        }} />
                    <TextField
                        fullWidth
                        size='small'
                        label='Telefon belgi'
                        style={{ margin: 5 }}
                        value={auth.user.phone || '...'}
                        disabled
                        InputProps={{
                            startAdornment: <InputAdornment position='start'><PhoneOutlined /></InputAdornment>
                        }} />
                    <div style={{ position: 'absolute', top: 4, right: 4, display: 'flex' }}>
                        {auth?.user?.type === 'ADMIN' && <NavLink to='/settings/'><IconButton><SettingsOutlined /></IconButton></NavLink>}
                        <IconButton onClick={handleSignOut}><ExitToAppOutlined /></IconButton>
                    </div>
                </>}
            </Card>
        </div>
    )
}