import { createStore, combineReducers } from "redux";
import { CartReducer } from "./CartReducer";
import { DatasReducer } from "./DatasReducer";
import { SearchReducer } from "./SearchReducer";



const TabReducer = (tab = parseInt(localStorage.getItem('tab')) || 0, action) => action.type === 'REDUCER/MAIN/TAB_INDEX' ? action.tab : tab
const AuthReducer = (auth = getInitiaAuthData(), action) => action.type === 'REDUCER/AUTH/DATA' ? action.data : auth

const getInitiaAuthData = () => {
    try {
        return JSON.parse(localStorage.getItem('auth-session'))
    } catch (e) {
        return {}
    }
}

const store = createStore(combineReducers({
    TAB: TabReducer,
    AUTH: AuthReducer,
    SEARCH: SearchReducer,
    DATAS: DatasReducer,
    CART: CartReducer
}), {});

window.store = store;


export default store