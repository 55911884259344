import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, Button, Dialog, DialogContent, DialogActions, TextField, CircularProgress } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import axios from 'axios';
import { API } from '../Constants';

export const Currencies = () => {
    const [currencies, setCurrencies] = React.useState([])
    const [selected, setSelected] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        if (!selected) {
            setLoading(true)
            const data = new FormData();
            data.append("data", JSON.stringify({
                type: 'currencies'
            }));
            axios.post(API.getData, data, {}).then(res => {
                setLoading(false)
                setCurrencies(res.data)
            })
        }
    }, [selected])

    return (
        <>
            <Table stickyHeader size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} />
                        <TableCell colSpan={2} align='right'><Button onClick={() => setSelected({ name: '', prop: 0 })} size='small' variant='outlined'>+</Button></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Ady</TableCell>
                        <TableCell>Kurs</TableCell>
                        <TableCell>Amal</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? <TableRow style={{ height: '100%' }}><TableCell align='center' colSpan={5}><CircularProgress size={40} /></TableCell></TableRow>
                        : currencies.map(category => <TableRow key={category.id}>
                            <TableCell>{category.name}</TableCell>
                            <TableCell align='center' width={60}>{category.prop}</TableCell>
                            <TableCell align='center' width={60}><IconButton onClick={() => setSelected(category)} size='small'><EditOutlined fontSize='small' /></IconButton></TableCell>
                        </TableRow>)}
                    <TableRow style={{ height: '100%' }} />
                </TableBody>
            </Table>
            {selected && <AddCategory data={selected} setData={setSelected} handleClose={() => setSelected(null)} />}
        </>
    )
}

const AddCategory = ({ data, handleClose, setData }) => {

    const handleDelete = () => {
        const data2 = new FormData();
        data2.append("data", JSON.stringify({ data, type: 'currencies', action: 'DELETE' }));
        axios.post(API.writeData, data2, {}).then(res => {
            handleClose()
        }).catch(console.error)
    }

    const handleSave = () => {
        const data2 = new FormData();
        data2.append("data", JSON.stringify({ data, type: 'currencies', action: 'SAVE' }))
        axios.post(API.writeData, data2, {}).then(res => {
            handleClose()
        }).catch(console.error)
    }

    return (
        <Dialog
            open
            onClose={handleClose}>
            <DialogContent style={{ width: 350 }}>
                <div style={{ margin: 10 }}>
                    <TextField
                        fullWidth
                        label='Ady'
                        size='small'
                        onChange={e => setData(data => ({ ...data, name: e.target.value }))}
                        value={data.name}
                    />
                </div>
                <div style={{ margin: 10 }}>
                    <TextField
                        fullWidth
                        label='Kurs'
                        size='small'
                        onChange={e => setData(data => ({ ...data, prop: e.target.value }))}
                        value={data.prop}
                        type='number'
                    />
                </div>
            </DialogContent>
            <DialogActions>
                {data.id && <Button onClick={handleDelete} size='small' variant='contained' color='secondary'>Poz</Button>}
                <Button onClick={handleSave} size='small' variant='contained' color='primary'>Ýatda sakla</Button>
            </DialogActions>
        </Dialog>
    )
}