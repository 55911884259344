const initialState = {
    text: '',
    category_1: '',
    category_2: '',
    subCategories: [],
    order: '',
    price: {
        min: 0,
        max: null
    },
    totalCount: 0,
    products: [],
    page: 0
}

export const SearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'REDUCER/SEARCH/TEXT':
            return {
                ...state,
                text: action.text,
                products: [],
                page: 0
            }
        case 'REDUCER/SEARCH/CATEGORY_1':
            return {
                ...state,
                category_1: action.category_1,
                category_2: action.category_2,
                subCategories: action.subCategories,
                products: [],
                page: 0
            }
        case 'REDUCER/SEARCH/CATEGORY_2':
            return {
                ...state,
                category_2: action.category_2,
                products: [],
                page: 0
            }
        case 'REDUCER/SEARCH/ORDER':
            return {
                ...state,
                order: action.order,
                products: [],
                page: 0
            }
        case 'REDUCER/SEARCH/PRICE':
            return {
                ...state,
                price: action.price,
                products: [],
                page: 0
            }
        case 'REDUCER/SEARCH/PRODUCTS':
            return {
                ...state,
                products: [
                    ...action.page > 0 ? state.products : [],
                    ...action.products
                ],
                totalCount: action.totalCount
            }
        case 'REDUCER/SEARCH/PRODUCTS-PAGE':
            return {
                ...state,
                page: state.page + 1
            }
        default:
            return state
    }
}