import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, Button, Dialog, DialogContent, DialogActions, TextField, TableContainer, TablePagination, InputAdornment, CircularProgress } from '@mui/material';
import { EditOutlined, KeyboardArrowDown, KeyboardArrowUp, SortOutlined } from '@mui/icons-material';
import axios from 'axios';
import { API } from '../Constants';


const data_keys = {
    name: 'Ady',
    phone: 'Telefon belgisi',
    note: 'Bellik',
    password: 'Gizlin kody'
}

export const Users = () => {
    const [state, setState] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [users, setusers] = React.useState([])
    const [selected, setSelected] = React.useState(null)
    const [datas, setDatas] = React.useState({
        users: [],
        page: 0,
        countPerPage: 10,
        totalCount: 0,
        order: 'name',
        orderType: 'ASC',
        loading: true
    })

    React.useEffect(() => {
        if (!selected) {
            setLoading(true)
            const data = new FormData();
            data.append("data", JSON.stringify({
                type: 'users'
            }));
            axios.post(API.getData, data, {}).then(res => {
                setLoading(false)
                setusers(res.data)
            })
        }
    }, [selected])


    React.useEffect(() => {
        setDatas(datas => ({
            ...datas,
            users: users.filter(user =>
                user.name.toLowerCase().includes((state?.name || '').toLowerCase()) &&
                user.phone.toLowerCase().includes((state?.phone || '').toLowerCase()) &&
                user.note.toLowerCase().includes((state?.note || '').toLowerCase()) &&
                user.password.toLowerCase().includes((state?.password || '').toLowerCase())
            )
        }))
    }, [state, users])

    return (
        <TableContainer style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', paddingTop: 10 }}>
            <Table stickyHeader size='small' style={{ flexGrow: 1 }}>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={3} />
                        <TableCell colSpan={2} align='right'><Button onClick={() => setSelected({ name: '', phone: '', password: '', note: '' })} size='small' variant='outlined'>+</Button></TableCell>
                    </TableRow>
                    <TableRow>
                        {Object.keys(data_keys).map((key, idx) => <TableCell style={{ padding: 3 }} key={key}>
                            <TextField
                                fullWidth
                                size='small'
                                value={state[key] || ''}
                                onChange={e => setState(state => ({ ...state, [key]: e.target.value }))}
                                label={data_keys[key]}
                                InputProps={{
                                    startAdornment: <InputAdornment position='start'>
                                        <IconButton size='small' onClick={() => setDatas(datas => ({ ...datas, order: key, orderType: datas.order === key && datas.orderType === 'ASC' ? 'DESC' : 'ASC' }))}>
                                            {key !== datas.order ? <SortOutlined fontSize='small' /> : datas.orderType === 'ASC' ? <KeyboardArrowDown fontSize='small' /> : <KeyboardArrowUp fontSize='small' />}
                                        </IconButton>
                                    </InputAdornment>
                                }} />
                        </TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? <TableRow style={{ height: '100%' }}><TableCell align='center' colSpan={5}><CircularProgress size={40} /></TableCell></TableRow>
                        : datas.users.slice(datas.countPerPage * datas.page, datas.countPerPage * (datas.page + 1)).sort((a, b) => a[datas.order].toLowerCase() > b[datas.order].toLowerCase() ? datas.orderType === 'ASC' ? 1 : -1 : datas.orderType === 'ASC' ? -1 : 1 - 1).map(user => <TableRow key={user.id}>
                            <TableCell>{user.name}</TableCell>
                            <TableCell>{user.phone}</TableCell>
                            <TableCell>{user.note}</TableCell>
                            <TableCell>{user.password}</TableCell>
                            <TableCell align='center' width={60}><IconButton onClick={() => setSelected(user)} size='small'><EditOutlined fontSize='small' /></IconButton></TableCell>
                        </TableRow>)}
                    <TableRow style={{ height: '100%' }} />
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={datas.users.length}
                page={datas.page}
                rowsPerPage={datas.countPerPage}
                onPageChange={(e, page) => setDatas(datas => ({ ...datas, page }))}
                onRowsPerPageChange={e => setDatas(datas => ({ ...datas, page: 0, countPerPage: parseInt(e.target.value, 10) }))}
            />
            {selected && <Adduser data={selected} setData={setSelected} handleClose={() => setSelected(null)} />}
        </TableContainer>
    )
}

const Adduser = ({ data, handleClose, setData }) => {

    const handleDelete = () => {
        const data2 = new FormData();
        data2.append("data", JSON.stringify({ data, type: 'users', action: 'DELETE' }))
        axios.post(API.writeData, data2, {}).then(res => {
            handleClose()
        }).catch(console.error)
    }

    const handleSave = () => {
        const data2 = new FormData();
        data2.append("data", JSON.stringify({ data, type: 'users', action: 'SAVE' }))
        axios.post(API.writeData, data2, {}).then(res => {
            handleClose()
        }).catch(console.error)
    }

    return (
        <Dialog
            open
            onClose={handleClose}>
            <DialogContent style={{ width: 350 }}>
                <div style={{ margin: 10 }}>
                    <TextField
                        fullWidth
                        label='Ady'
                        size='small'
                        onChange={e => setData(data => ({ ...data, name: e.target.value }))}
                        value={data.name}
                    />
                </div>
                <div style={{ margin: 10 }}>
                    <TextField
                        fullWidth
                        label='Telefon belgisi'
                        size='small'
                        onChange={e => setData(data => ({ ...data, phone: e.target.value }))}
                        value={data.phone}
                    />
                </div>
                <div style={{ margin: 10 }}>
                    <TextField
                        fullWidth
                        label='Bellik'
                        size='small'
                        onChange={e => setData(data => ({ ...data, note: e.target.value }))}
                        value={data.note}
                    />
                </div>
                <div style={{ margin: 10 }}>
                    <TextField
                        fullWidth
                        label='Gizlin kody'
                        size='small'
                        onChange={e => setData(data => ({ ...data, password: e.target.value }))}
                        value={data.password}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                {data.id && <Button onClick={handleDelete} size='small' variant='contained' color='secondary'>Poz</Button>}
                <Button onClick={handleSave} size='small' variant='contained' color='primary'>Ýatda sakla</Button>
            </DialogActions>
        </Dialog>
    )
}