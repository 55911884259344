import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, Button, Dialog, DialogContent, DialogActions, TextField, CircularProgress, Chip, Grid } from '@mui/material';
import { AddPhotoAlternateOutlined, EditOutlined } from '@mui/icons-material';
import axios from 'axios';
import { API } from '../Constants';
import Draggable from 'react-draggable';

export const Baners = () => {
    const [baners, setbaners] = React.useState([])
    const [selected, setSelected] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        if (!selected) {
            setLoading(true)
            const data = new FormData();
            data.append("data", JSON.stringify({
                type: 'baners'
            }));
            axios.post(API.getData, data, {}).then(res => {
                setLoading(false)
                setbaners(res.data)
            }).catch(e => {
                setLoading(false)
            })
        }
    }, [selected])

    const handleClear = () => {
        const data = new FormData()
        data.append('table', 'baners')
        axios.post(API.clearData, data, {
        }).then((res) => {
            setLoading(true)
            const data = new FormData();
            data.append("data", JSON.stringify({
                type: 'baners'
            }));
            axios.post(API.getData, data, {}).then(res => {
                setLoading(false)
                setbaners(res.data)
            }).catch(e => {
                setLoading(false)
            })
        }).catch(e => {
            console.error(e)
        })
    }
    return (
        <>
            <Table stickyHeader size='small' style={{ flexGrow: 1 }}>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell colSpan={2} align='right'>
                            <div align='right' style={{ marginBottom: 10, zoom: 0.5 }}><Button onClick={handleClear} size='small' color='secondary' variant='contained'>Arassala</Button></div>
                            <Button onClick={() => setSelected({ images: [], idx: 0 })} size='small' variant='outlined'>+</Button>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Suraty</TableCell>
                        <TableCell>Tertip nomeri</TableCell>
                        <TableCell>Amal</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? <TableRow style={{ height: '100%' }}><TableCell align='center' colSpan={5}><CircularProgress size={40} /></TableCell></TableRow>
                        : baners.map(baner => <TableRow key={baner.id}>
                            <TableCell>
                                <div style={{ display: 'flex' }}>
                                    {baner.images.slice(0, 4).map(image =>
                                        <div className='image-view' style={{ boxShadow: '0 0 2px gray', height: 60, width: 60, margin: 10, position: 'relative', backgroundImage: `url(${encodeURI(image)})` }} />
                                    )}
                                    {baner.images.length > 4 && <Chip label={`+${baner.images.length - 4}`} size='small' />}
                                </div>
                            </TableCell>
                            <TableCell align='center' width={60}>{baner.idx}</TableCell>
                            <TableCell align='center' width={60}><IconButton onClick={() => setSelected(baner)} size='small'><EditOutlined fontSize='small' /></IconButton></TableCell>
                        </TableRow>)}
                    <TableRow style={{ height: '100%' }} />
                </TableBody>
            </Table>
            {selected && <Addbaner data={selected} setData={setSelected} handleClose={() => setSelected(null)} />}
        </>
    )
}

const Addbaner = ({ data, handleClose, setData }) => {

    const handleDelete = () => {
        const data2 = new FormData();
        data2.append("data", JSON.stringify({ data, type: 'baners', action: 'DELETE' }));
        axios.post(API.writeData, data2, {}).then(res => {
            handleClose()
        }).catch(console.error)
    }

    const handleSave = () => {
        const data2 = new FormData();
        data2.append("data", JSON.stringify({ data, type: 'baners', action: 'SAVE' }));
        axios.post(API.writeData, data2, {}).then(res => {
            handleClose()
        }).catch(console.error)
    }

    return (
        <Dialog
            open
            maxWidth='sm'
            onClose={handleClose}>
            <DialogContent>
                <div style={{ margin: 10 }}>
                    <TextField
                        fullWidth
                        label='Tertip nomeri'
                        size='small'
                        onChange={e => setData(data => ({ ...data, idx: e.target.value }))}
                        value={data.idx}
                        type='number'
                    />
                </div>
                <ImageArrayLoader
                    images={data.images || []}
                    onChange={(callback) => setData(data => ({
                        ...data,
                        images: callback(data.images)
                    }))} />
            </DialogContent>
            <DialogActions>
                {data.id && <Button onClick={handleDelete} size='small' variant='contained' color='secondary'>Poz</Button>}
                <Button onClick={handleSave} size='small' variant='contained' color='primary'>Ýatda sakla</Button>
            </DialogActions>
        </Dialog>
    )
}











const ImageArrayLoader = ({ onChange, images, ...props }) => {
    const [state, setState] = React.useState({
        drag: -1,
        over: -1
    })

    const handleStop = () => {
        onChange(images => {
            const filtered = [...images.slice(0, state.drag), ...images.slice(state.drag + 1)]
            const i1 = filtered.slice(0, state.over)
            const i2 = filtered.slice(state.over)
            return [...i1, images[state.drag], ...i2]
        })
        setState({
            drag: -1,
            over: -1
        })
    }

    const handleChange = event => {
        if (event.target.files.length > 0) {
            const data = new FormData();

            for (let i = 0; i < event.target.files.length; i++) {
                data.append("file[]", event.target.files[i]);
            }

            axios.post(API.MULTIPLE_FILES, data, {}).then(res =>
                onChange(images => [...images, ...(res.data || [])])
            ).catch(console.error)
            event.target.value = null
        }
    }

    const style = {
        height: 100,
        width: '100%',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    }

    const { drag, over } = state
    const getBckIdx = idx => drag === idx ? over < drag ? idx - 1 : idx + 1 : idx
    const getIdx = idx => ((idx >= over && idx < drag) || (idx <= over && idx > drag)) && drag > -1 ? over < drag ? idx - 1 : idx + 1 : idx
    const handleRemove = (e, idx) => {
        e.preventDefault();
        onChange(images => images.filter((image, i) => i !== idx))
    }
    return (
        <div style={{ height: props.height || 250, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
            <div className='mini-scroll' style={{ flexGrow: 1, overflow: 'hidden scroll', padding: 10 }}>
                <Grid
                    container
                    spacing={2}
                >
                    {images.map((image, idx) =>
                        <Grid
                            key={idx}
                            item
                            xs={6}
                            sm={3}
                            lg={2}
                            onContextMenu={e => handleRemove(e, idx)}
                            {...props.sizes || {}}
                            style={{
                                opacity: idx === over && drag > -1 && drag !== over ? 0 : 1,
                                zIndex: idx === drag ? 0 : 1,
                                position: 'relative'
                            }}
                            onMouseOver={() => setState(state => ({ ...state, over: idx }))}
                        >
                            <Button style={{ ...style, backgroundImage: `url(${encodeURI(`${images[getBckIdx(idx)]}`)})`, position: 'absolute', opacity: drag === idx ? 0.7 : 0, top: 3, left: 3 }} variant='outlined'>
                            </Button>
                            <Draggable
                                position={{ x: 0, y: 0 }}
                                onStart={() => setState(state => ({ ...state, drag: idx }))}
                                onStop={handleStop}
                            >
                                <Button style={{ ...style, backgroundImage: `url(${encodeURI(`${images[getIdx(idx)]}`)})` }} variant='outlined'>
                                </Button>
                            </Draggable>
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={6}
                        sm={3}
                        lg={2}
                        {...props.sizes || {}}
                        style={{ position: 'relative' }}>
                        <Button style={style} variant='outlined'>
                            <AddPhotoAlternateOutlined />
                            <input
                                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }}
                                type='file'
                                multiple
                                onChange={handleChange}
                            />
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}