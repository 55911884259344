import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, Button, Dialog, DialogContent, DialogActions, TextField, FormControlLabel, Switch, CircularProgress } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import axios from 'axios';
import { API } from '../Constants';
import { useSelector } from 'react-redux';

export const Admins = () => {
    const [admins, setAdmins] = React.useState([])
    const [selected, setSelected] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        if (!selected) {
            setLoading(true)
            const data = new FormData();
            data.append("data", JSON.stringify({
                type: 'admins'
            }));
            axios.post(API.getData, data, {}).then(res => {
                setLoading(false)
                setAdmins(res.data)
            })
        }
    }, [selected])

    return (
        <>
            <Table stickyHeader size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={8} />
                        <TableCell colSpan={2} align='right'><Button onClick={() => setSelected({
                            name: '',
                            password: ''

                        })} size='small' variant='outlined'>+</Button></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Ady</TableCell>
                        <TableCell>Harytlar</TableCell>
                        <TableCell>Sargytlar</TableCell>
                        <TableCell>Kategoriýalar</TableCell>
                        <TableCell>Brendler</TableCell>
                        <TableCell>Banerler</TableCell>
                        <TableCell>Walýutalar</TableCell>
                        <TableCell>Müşderiler</TableCell>
                        <TableCell>Sazlamalar</TableCell>
                        <TableCell>Ulanyjylar</TableCell>
                        <TableCell>Amal</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? <TableRow style={{ height: '100%' }}><TableCell align='center' colSpan={5}><CircularProgress size={40} /></TableCell></TableRow>
                        : admins.map(admin => <TableRow key={admin.id}>
                            <TableCell>{admin.name}</TableCell>
                            <TableCell align='center' width={60}>{admin.products}</TableCell>
                            <TableCell align='center' width={60}>{admin.cart}</TableCell>
                            <TableCell align='center' width={60}>{admin.categories}</TableCell>
                            <TableCell align='center' width={60}>{admin.brands}</TableCell>
                            <TableCell align='center' width={60}>{admin.baner}</TableCell>
                            <TableCell align='center' width={60}>{admin.currencies}</TableCell>
                            <TableCell align='center' width={60}>{admin.clients}</TableCell>
                            <TableCell align='center' width={60}>{admin.settings}</TableCell>
                            <TableCell align='center' width={60}>{admin.admins}</TableCell>
                            <TableCell align='center' width={60}><IconButton onClick={() => setSelected(admin)} size='small'><EditOutlined fontSize='small' /></IconButton></TableCell>
                        </TableRow>)}
                    <TableRow style={{ height: '100%' }} />
                </TableBody>
            </Table>
            {selected && <Addadmin data={selected} setData={setSelected} handleClose={() => setSelected(null)} />}
        </>
    )
}

const Addadmin = ({ data, handleClose, setData }) => {
    const admin = useSelector(state => parseInt(state.AUTH?.user?.admin) === 1)

    const data2 = new FormData();
    data2.append("data", JSON.stringify({ data, type: 'admins', action: 'DELETE' }));
    const handleDelete = () => {
        axios.post(API.writeData, data2, {}).then(res => {
            handleClose()
        }).catch(console.error)
    }

    const handleSave = () => {
        const data2 = new FormData();
        data2.append("data", JSON.stringify({ data, type: 'admins', action: 'SAVE' }));
        axios.post(API.writeData, data2, {}).then(res => {
            handleClose()
        }).catch(console.error)
    }

    return (
        <Dialog
            open
            onClose={handleClose}>
            <DialogContent style={{ width: 350 }}>
                <div style={{ margin: 10 }}>
                    <TextField
                        fullWidth
                        label='Ady'
                        size='small'
                        onChange={e => setData(data => ({ ...data, name: e.target.value }))}
                        value={data.name}
                    />
                </div>
                {admin &&
                    <div style={{ margin: 10 }}>
                        <TextField
                            fullWidth
                            label='Gizlin kody'
                            size='small'
                            onChange={e => setData(data => ({ ...data, password: e.target.value }))}
                            value={data.password}
                        />
                    </div>}
                <div style={{ margin: 10 }}><FormControlLabel control={<Switch checked={parseInt(data.products) === 1} onChange={e => setData(data => ({ ...data, products: e.target.checked ? 1 : 0 }))} />} label='Harytlary üýtgedip biler' /></div>
                <div style={{ margin: 10 }}><FormControlLabel control={<Switch checked={parseInt(data.cart) === 1} onChange={e => setData(data => ({ ...data, cart: e.target.checked ? 1 : 0 }))} />} label='Sargytlary kabul edip biler' /></div>
                <div style={{ margin: 10 }}><FormControlLabel control={<Switch checked={parseInt(data.categories) === 1} onChange={e => setData(data => ({ ...data, categories: e.target.checked ? 1 : 0 }))} />} label='Kategoriýalary üýtgedip biler' /></div>
                <div style={{ margin: 10 }}><FormControlLabel control={<Switch checked={parseInt(data.brands) === 1} onChange={e => setData(data => ({ ...data, brands: e.target.checked ? 1 : 0 }))} />} label='Brendleri üýtgedip biler' /></div>
                <div style={{ margin: 10 }}><FormControlLabel control={<Switch checked={parseInt(data.baners) === 1} onChange={e => setData(data => ({ ...data, baners: e.target.checked ? 1 : 0 }))} />} label='Banerleri biler' /></div>
                <div style={{ margin: 10 }}><FormControlLabel control={<Switch checked={parseInt(data.currencies) === 1} onChange={e => setData(data => ({ ...data, currencies: e.target.checked ? 1 : 0 }))} />} label='Walýuta kurslary üýtgedip biler' /></div>
                <div style={{ margin: 10 }}><FormControlLabel control={<Switch checked={parseInt(data.clients) === 1} onChange={e => setData(data => ({ ...data, clients: e.target.checked ? 1 : 0 }))} />} label='Müşderileri üýtgedip biler' /></div>
                <div style={{ margin: 10 }}><FormControlLabel control={<Switch checked={parseInt(data.settings) === 1} onChange={e => setData(data => ({ ...data, settings: e.target.checked ? 1 : 0 }))} />} label='Sazlamalary üýtgedip biler' /></div>
                <div style={{ margin: 10 }}><FormControlLabel control={<Switch checked={parseInt(data.admins) === 1} onChange={e => setData(data => ({ ...data, admins: e.target.checked ? 1 : 0 }))} />} label='Ulanyjy rugsatlary üýtgedip biler' /></div>
            </DialogContent>
            <DialogActions>
                {data.id && <Button onClick={handleDelete} size='small' variant='contained' color='secondary'>Poz</Button>}
                <Button onClick={handleSave} size='small' variant='contained' color='primary'>Ýatda sakla</Button>
            </DialogActions>
        </Dialog>
    )
}