import { HelpOutline, KeyboardArrowDown, KeyboardArrowUp, SortOutlined } from '@mui/icons-material'
import { CircularProgress, Badge, IconButton, Button, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { API } from '../Constants'
import { Menu, Dropdown } from 'antd';
import { useSelector } from 'react-redux';

const data_keys = {
    image: 'Suraty',
    isactive: 'Status',
    name: 'Ady',
    code: 'Kody',
    measure: 'Esasy ölçegi',
    price_base_for_sale: 'Satyş bahasy',
    currency: 'Walýutasy',
    property_1: '1-nji aýratyklyk',
    property_2: '2-nji aýratyklyk',
    property_3: '3-nji aýratyklyk',
    property_4: '4-nji aýratyklyk',
    property_5: '5-nji aýratyklyk',
}

export const Products = () => {
    const [state, setState] = React.useState({})
    const [datas, setDatas] = React.useState({
        products: [],
        page: 0,
        countPerPage: 10,
        totalCount: 0,
        order: 'name',
        orderType: 'ASC',
        loading: true
    })

    const [selected, setSelected] = React.useState(null)

    const [newDatas, setNewDatas] = React.useState([])
    const [reload, setReload] = React.useState(0)

    React.useEffect(() => {
        let timer = null
        if (!selected) {
            const getData = () => {
                axios.get(API.newData).then(res => {
                    setNewDatas(res.data instanceof Array ? res.data : [])
                    timer = setTimeout(getData, 10000)
                }).catch(e => {
                    timer = setTimeout(getData, 5000)
                })
            }
            getData()
        }
        return () => timer && clearTimeout(timer)
    }, [selected])

    React.useEffect(() => {
        let timer = null
        setDatas(datas => ({
            ...datas,
            loading: true
        }))
        if (!selected) {
            timer = setTimeout(() => {
                const data = new FormData();
                data.append("data", JSON.stringify({
                    ...state,
                    page: datas.page * datas.countPerPage,
                    perPage: datas.countPerPage,
                    order: datas.order,
                    orderType: datas.orderType
                }));
                axios.post(API.getProducts, data, {}).then((res) => {
                    setDatas(datas => ({
                        ...datas,
                        ...res.data,
                        loading: false
                    }))
                }).catch(e => {
                    setDatas(datas => ({
                        ...datas,
                        loading: false
                    }))
                })
            }, 2000)
        }
        return () => timer && clearTimeout(timer)
    }, [datas.page, datas.countPerPage, datas.order, state, datas.orderType, selected, reload])

    const menu = (
        <Menu>
            {newDatas.map(data => <Menu.Item key={data.id} onClick={() => setSelected(data)}>{data.date}</Menu.Item>)}
        </Menu>
    )

    const getPopupContainerHandler = (e) => {
        return document.getElementById('ant-design') || document.body
    }

    const handleUpload = id => e => {
        let files = e.target.files;
        if (files.length === 0) return
        const data = new FormData()
        data.append('file', files[0])
        data.append('id', id)
        axios.post(API.uploadImage, data, {
        }).then((res) => {
            setDatas(datas => ({
                ...datas,
                products: datas.products.map(p => p.id === res.data?.id ? { ...p, image: res.data.image } : p)
            }))
        }).catch(e => {
            console.error(e)
        })
    }

    const handleClear = () => {
        const data = new FormData()
        data.append('table', 'products')
        axios.post(API.clearData, data, {
        }).then((res) => {
            setState(state => ({ ...state, requery: (state.requery || 0) + 1 }))
        }).catch(e => {
            console.error(e)
        })
    }

    return (
        <TableContainer style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', paddingTop: 10 }}>
            <div align='right' style={{ marginBottom: 10, zoom: 0.5 }}><Button onClick={handleClear} size='small' color='secondary' variant='contained'>Arassala</Button></div>
            <Table size='small' stickyHeader style={{ flexGrow: 1 }}>
                <TableHead>
                    <TableRow>
                        {Object.keys(data_keys).map((key, idx) => <TableCell style={{ padding: 3 }} key={key}>{idx === 0 ? data_keys[key] :
                            <TextField
                                fullWidth
                                size='small'
                                value={state[key] || ''}
                                onChange={e => setState(state => ({ ...state, [key]: e.target.value }))}
                                label={data_keys[key]}
                                InputProps={{
                                    startAdornment: <InputAdornment position='start'>
                                        <IconButton size='small' onClick={() => setDatas(datas => ({ ...datas, order: key, orderType: datas.order === key && datas.orderType === 'ASC' ? 'DESC' : 'ASC' }))}>
                                            {key !== datas.order ? <SortOutlined fontSize='small' /> : datas.orderType === 'ASC' ? <KeyboardArrowDown fontSize='small' /> : <KeyboardArrowUp fontSize='small' />}
                                        </IconButton>
                                    </InputAdornment>
                                }} />}
                        </TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {datas.loading ? <TableRow style={{ height: '100%' }}><TableCell align='center' colSpan={11}><CircularProgress size={40} /></TableCell></TableRow> : datas.products.length === 0 ? <TableRow><TableCell align='center' colSpan={11}>Tapylmady!</TableCell></TableRow> : datas.products.map(product => <TableRow key={product.id}>
                        {Object.keys(data_keys).map((key, idx) => <TableCell style={{ padding: 3 }} key={key}>
                            {idx === 0 ?
                                <div className='image-view' style={{ width: 60, height: 60, position: 'relative', backgroundImage: `url(${encodeURI(product[key])})` }}><input onChange={handleUpload(product.id)} type='file' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} /></div>
                                :
                                key === 'price_base_for_sale' ? <EditPrice setReload={setReload} price={product[key]} id={product.id} /> : product[key]
                            }
                        </TableCell>)}
                    </TableRow>)}
                    <TableRow style={{ height: '100%' }} />
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={datas.totalCount}
                page={datas.page}
                rowsPerPage={datas.countPerPage}
                onPageChange={(e, page) => setDatas(datas => ({ ...datas, page }))}
                onRowsPerPageChange={e => setDatas(datas => ({ ...datas, page: 0, countPerPage: parseInt(e.target.value, 10) }))}
            />
            {newDatas.length > 0 && <IconButton size='large' style={{ position: 'fixed', right: 10, bottom: 60 }}><Dropdown getPopupContainer={getPopupContainerHandler} overlay={menu} placement="topLeft"><Badge badgeContent={newDatas.length} color="secondary"><HelpOutline /></Badge></Dropdown></IconButton>}
            {selected && <ShowNewData data={selected} handleClose={() => setSelected(null)} />}
        </TableContainer>
    )
}

const EditPrice = ({ id, price, setReload }) => {
    const [edited, setEdited] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const handleSave = (askAccess = false) => {
        if (askAccess || window.confirm('Baha ýatda saklansynmy')) {
            setLoading(true)
            const data2 = new FormData();
            data2.append("data", JSON.stringify({ id, price: edited }))
            axios.post(API.savePrice, data2, {}).then(res => {
                if (res.data) {
                    setReload(reload => reload + 1)
                    setEdited(null)
                }
                setLoading(false)
            }).catch(e => {
                console.error(e)
                setLoading(false)
            })
        } else {
            setEdited(null)
        }
    }
    return edited === null ? <div onDoubleClick={() => setEdited(parseFloat(price))}>{price}</div> :
        <TextField
            label='Bahasy'
            size='small'
            autoFocus
            disabled={loading}
            InputProps={{
                startAdornment: loading ? <CircularProgress size={20} /> : null
            }}
            onKeyDown={e => e.keyCode === 13 ? handleSave(true) : e.keyCode === 27 ? setEdited(null) : {}}
            type='number'
            onBlur={() => handleSave()}
            value={edited}
            onChange={e => setEdited(e.target.value)}
        />
}

const ShowNewData = ({ data, handleClose }) => {
    const [datas, setDatas] = React.useState({
        products: [],
        loading: true
    })

    const session = useSelector(state => state.AUTH.session)

    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        axios.get(`${API.FILES}${data.id}`).then(res => {
            setDatas({
                products: res.data instanceof Array ? res.data : [],
                loading: false
            })
        }).catch(e => {
            setDatas({
                products: [],
                loading: false
            })
        })
    }, [data.id])

    const handleDelete = () => {
        setLoading(true)
        const data = new FormData();
        data.append("data", JSON.stringify({
            id: data.id,
            session,
            action: 'DELETE'
        }))
        axios.post(API.fileAction, data, {}).then(res => {
            handleClose()
        }).catch(e => {
            setLoading(false)
        })
    }

    const handleSave = () => {
        setLoading(true)
        const data2 = new FormData();
        data2.append("data", JSON.stringify({
            id: data.id,
            session,
            action: 'SAVE',
            products: datas.products
        }))
        axios.post(API.fileAction, data2, {}).then(res => {
            handleClose()
        }).catch(e => {
            setLoading(false)
        })
    }

    return (
        <Dialog
            open
            onClose={loading ? () => { } : handleClose}
            maxWidth='lg'>
            <DialogTitle>{data.date}</DialogTitle>
            <DialogContent>
                <Table size='small' stickyHeader style={{ flexGrow: 1 }}>
                    <TableHead>
                        <TableRow>
                            {Object.keys(data_keys).map(key => <TableCell style={{ padding: 3 }} key={key}>{data_keys[key]}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datas.loading ? <TableRow><TableCell align='center' colSpan={11}><CircularProgress size={40} /></TableCell></TableRow> : datas.products.length === 0 ? <TableRow><TableCell align='center' colSpan={11}>Tapylmady!</TableCell></TableRow> : datas.products.map(product => <TableRow key={product.id}>
                            {Object.keys(data_keys).map(key => <TableCell style={{ padding: 3 }} key={key}>
                                {product[key]}
                            </TableCell>)}
                        </TableRow>)}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                {loading || <Button onClick={handleDelete} variant='contained' size='small' color='secondary'>Poz</Button>}
                {loading || <Button onClick={handleSave} variant='contained' size='small' color='primary'>Ýatda sakla</Button>}
            </DialogActions>
        </Dialog>
    )
}