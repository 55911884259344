import { AccountBalanceOutlined, BrandingWatermarkOutlined, MoneyOutlined, ExitToAppOutlined, MoreVertRounded, PersonOutline, PersonPinOutlined, SettingsOutlined, ShoppingCartOutlined, ImageOutlined } from '@mui/icons-material'
import CategoryOutlined from '@mui/icons-material/CategoryOutlined'
import { AppBar, Avatar, Hidden, Card, IconButton, List, ListItem, ListSubheader, Toolbar, Typography, Drawer } from '@mui/material'
import React from 'react'
import { NavLink, Switch, Route } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Products } from './Products';
import { Categories } from './Categories';
import { Brands } from './Brands';
import { Currencies } from './Currencies';
import { Users } from './Users';
import { Admins } from './Admins';
import { Settings as SettingsMain } from './settings';
import axios from 'axios';
import { API } from '../Constants';
import { Baners } from './Baners';
import { CartSettings } from './Cart';

export const Settings = connect(state => ({ auth: state.AUTH }))((props) => {
    const [open, setOpen] = React.useState(false)
    const session = props.auth.session
    React.useEffect(() => {
        if (props.auth?.user?.type !== 'ADMIN') {
            props.history.replace('/')
        }
    }, [props.auth.user.type, props.history])
    const dispatch = useDispatch()

    React.useEffect(() => {
        let timer = null
        const getData = () => {
            const data = new FormData();
            data.append("data", JSON.stringify({
                session
            }));
            axios.post(API.authCache, data, {}).then((res) => {
                if (res.data?.session) {
                    dispatch({
                        type: 'REDUCER/AUTH/DATA',
                        data: res.data
                    })
                }
                timer = setTimeout(getData, 10000)
            }).catch(console.error)
        }
        getData()
        return () => timer && clearTimeout(timer)
    }, [dispatch, session])

    return (
        <div style={{ minHeight: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
            <AppBar position='sticky' color='transparent'>
                <Toolbar>
                    <Hidden smUp>
                        <IconButton onClick={() => setOpen(open => !open)}><MoreVertRounded /></IconButton>
                    </Hidden>
                    <Typography variant='h6'>Sazlamalar</Typography>
                    <div style={{ flexGrow: 1, minWidth: 20 }} />
                    <IconButton onClick={() => props.history.replace('/')}><ExitToAppOutlined /></IconButton>
                </Toolbar>
            </AppBar>
            <div style={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
                <Hidden smDown>
                    <LeftMenu auth={props.auth} />
                </Hidden>
                <div style={{ margin: 4, flexGrow: 1, overflow: 'auto', display: 'flex' }}>
                    <Switch>
                        {(parseInt(props.auth?.user?.admin) === 1 || parseInt(props.auth?.user?.products) === 1) && <Route path='/settings/products' component={Products} />}
                        {(parseInt(props.auth?.user?.admin) === 1 || parseInt(props.auth?.user?.categories) === 1) && <Route path='/settings/categories' component={Categories} />}
                        {(parseInt(props.auth?.user?.admin) === 1 || parseInt(props.auth?.user?.currencies) === 1) && <Route path='/settings/currencies' component={Currencies} />}
                        {(parseInt(props.auth?.user?.admin) === 1 || parseInt(props.auth?.user?.brands) === 1) && <Route path='/settings/brands' component={Brands} />}
                        {(parseInt(props.auth?.user?.admin) === 1 || parseInt(props.auth?.user?.clients) === 1) && <Route path='/settings/clients' component={Users} />}
                        {(parseInt(props.auth?.user?.admin) === 1 || parseInt(props.auth?.user?.baners) === 1) && <Route path='/settings/baners' component={Baners} />}
                        {(parseInt(props.auth?.user?.admin) === 1 || parseInt(props.auth?.user?.admins) === 1) && <Route path='/settings/users' component={Admins} />}
                        {(parseInt(props.auth?.user?.admin) === 1 || parseInt(props.auth?.user?.cart) === 1) && <Route path='/settings/carts' component={CartSettings} />}
                        {(parseInt(props.auth?.user?.admin) === 1 || parseInt(props.auth?.user?.settings) === 1) && <Route path='/settings/settings' component={SettingsMain} />}
                        <Route path='/settings' component={() => <div className='App-logo' style={{ margin: 'auto' }}>ONLINE APP</div>} />
                    </Switch>
                </div>
            </div>
            <Drawer
                open={open}
                onClose={() => setOpen(false)}>
                <LeftMenu handleClose={() => setOpen(false)} auth={props.auth} />
            </Drawer>
        </div>
    )
})

const LeftMenu = ({ handleClose = () => { }, auth }) => {
    return (
        <Card style={{ width: 200, maxWidth: 200, minWidth: 200, flexGrow: 1, margin: 4, overflow: 'auto' }} elevation={3}>
            <List subheader={<ListSubheader>Menu</ListSubheader>}>
                {(parseInt(auth?.user?.admin) === 1 || parseInt(auth?.user?.products) === 1) && <ListItem onClick={handleClose} component={NavLink} to='/settings/products'><Avatar style={{ marginRight: 5, width: 30, height: 30 }}><AccountBalanceOutlined fontSize='small' /></Avatar><span>Harytlar</span></ListItem>}
                {(parseInt(auth?.user?.admin) === 1 || parseInt(auth?.user?.cart) === 1) && <ListItem onClick={handleClose} component={NavLink} to='/settings/carts'><Avatar style={{ marginRight: 5, width: 30, height: 30 }}><ShoppingCartOutlined fontSize='small' /></Avatar><span>Sargytlar</span></ListItem>}
                {(parseInt(auth?.user?.admin) === 1 || parseInt(auth?.user?.categories) === 1) && <ListItem onClick={handleClose} component={NavLink} to='/settings/categories'><Avatar style={{ marginRight: 5, width: 30, height: 30 }}><CategoryOutlined fontSize='small' /></Avatar><span>Kategoriýalar</span></ListItem>}
                {(parseInt(auth?.user?.admin) === 1 || parseInt(auth?.user?.brands) === 1) && <ListItem onClick={handleClose} component={NavLink} to='/settings/brands'><Avatar style={{ marginRight: 5, width: 30, height: 30 }}><BrandingWatermarkOutlined fontSize='small' /></Avatar><span>Brendler</span></ListItem>}
                {(parseInt(auth?.user?.admin) === 1 || parseInt(auth?.user?.baners) === 1) && <ListItem onClick={handleClose} component={NavLink} to='/settings/baners'><Avatar style={{ marginRight: 5, width: 30, height: 30 }}><ImageOutlined fontSize='small' /></Avatar><span>Banerler</span></ListItem>}
                {(parseInt(auth?.user?.admin) === 1 || parseInt(auth?.user?.currencies) === 1) && <ListItem onClick={handleClose} component={NavLink} to='/settings/currencies'><Avatar style={{ marginRight: 5, width: 30, height: 30 }}><MoneyOutlined fontSize='small' /></Avatar><span>Walýutalar</span></ListItem>}
                {(parseInt(auth?.user?.admin) === 1 || parseInt(auth?.user?.clients) === 1) && <ListItem onClick={handleClose} component={NavLink} to='/settings/clients'><Avatar style={{ marginRight: 5, width: 30, height: 30 }}><PersonOutline fontSize='small' /></Avatar><span>Müşderiler</span></ListItem>}
                {(parseInt(auth?.user?.admin) === 1 || parseInt(auth?.user?.settings) === 1) && <ListItem onClick={handleClose} component={NavLink} to='/settings/settings'><Avatar style={{ marginRight: 5, width: 30, height: 30 }}><SettingsOutlined fontSize='small' /></Avatar><span>Sazlamalar</span></ListItem>}
                {(parseInt(auth?.user?.admin) === 1 || parseInt(auth?.user?.admin) === 1) && <ListItem onClick={handleClose} component={NavLink} to='/settings/users'><Avatar style={{ marginRight: 5, width: 30, height: 30 }}><PersonPinOutlined fontSize='small' /></Avatar><span>Ulanyjylar</span></ListItem>}
            </List>
        </Card>
    )
}