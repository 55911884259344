import React from 'react'
import { Typography, Button, Divider, Container, CircularProgress } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { AddOutlined, RemoveOutlined } from '@mui/icons-material';
import { useSelector, useDispatch, connect } from 'react-redux';
import axios from 'axios';
import { API } from '../Constants';

export const ProductDetails = connect((state, props) => ({ product: state.DATAS.products?.[props.match.params.id] || { id: props.match.params.id, notFound: true, name: '...', image: '' } }))(({ product }) => {
    const [loading, setLoading] = React.useState(false)
    const count = useSelector(state => state.CART.products?.[product.id] || 0)
    const dispatch = useDispatch()
    const handleCart = count => {
        dispatch({
            type: 'REDUCER/CART/CHANGE_COUNT',
            count,
            id: product.id
        })
    }
    React.useEffect(() => {
        if (product.notFound) {
            setLoading(true)
            axios.get(`${API.PRODUCT}&id=${product.id}`).then(res => {
                setLoading(false)
                dispatch({
                    type: 'REDUCER/DATAS/PRODUCT_DATA',
                    id: res.data.id,
                    data: res.data
                })
            }).catch(e => {
                setLoading(false)
                console.error(e)
            })
        }
    }, [product.id, product.notFound, dispatch])
    return (
        <Container maxWidth='sm' style={{ margin: 'auto', height: 'calc(100% - 4px)', display: 'flex', background: 'white' }}>
            <div style={{ padding: 8, display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
                <NavLink to={`/product-details/${product.id}`}><div className='image-view' style={{ height: 350, backgroundImage: `url(${encodeURI(product.image)})`, marginBottom: 4 }} /></NavLink>
                <Divider />
                {loading ? <div style={{ textAlign: 'center' }}><CircularProgress size={30} /></div> : <>
                    <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
                        <NavLink to={`/product-details/${product.id}`}><Typography variant='caption'>{product.name}</Typography></NavLink>
                        <div style={{ flexGrow: 1 }} />
                        <NavLink to={`/product-details/${product.id}`}><Typography variant='subtitle1' color='primary'>{`${parseFloat(product.price).toFixed(2)} TMT`}</Typography></NavLink>
                    </div>
                    <div style={{ flexGrow: 1 }}><NavLink to={`/product-details/${product.id}`}><Typography variant='caption'>{product.description}</Typography></NavLink></div>
                    {count > 0 ? <div style={{ display: 'flex', width: '50%', margin: 'auto' }}>
                        <Button onClick={() => handleCart(count - 1)} size='small' variant='contained' style={{ minWidth: 0 }}><RemoveOutlined fontSize='small' /></Button>
                        <Typography variant='caption' style={{ margin: 4, flexGrow: 1, textAlign: 'center' }}>{count}</Typography>
                        <Button onClick={() => handleCart(count + 1)} size='small' variant='contained' style={{ minWidth: 0 }}><AddOutlined fontSize='small' /></Button>
                    </div> : <Button onClick={() => handleCart(1)} size='small' variant='contained'>Sebede goş</Button>}
                </>}
            </div>
        </Container>
    )
})