import React from 'react'
import './App.css';
import { HomePage } from './Pages/HomePage';
import { SearchPage } from './Pages/SearchPage';
import { CartPage } from './Pages/CartPage';
import { ProfilePage } from './Pages/ProfilePage';
import "react-image-gallery/styles/css/image-gallery.css";
import 'antd/dist/antd.css';
import { Header } from './Components/Header';
import { useSelector, useDispatch } from 'react-redux';
import { Footer } from './Components/Footer';
import { Switch, Route } from 'react-router-dom';
import { Settings } from './Settings';
import axios from 'axios';
import { API } from './Constants';
import { Container, IconButton } from '@mui/material';
import { ProductDetails } from './Pages/ProductDetails';
import { KeyboardArrowUp } from '@mui/icons-material';


function App() {
  const value = useSelector(state => state.TAB)
  const dispatch = useDispatch()
  React.useEffect(() => {
    let timer = null
    const getDatas = () => {
      axios.get(API.DATAS).then(res => {
        if (res.data?.categories && res.data?.brands) {
          dispatch({ type: 'REDUCER/DATAS/DATAS', categories: res.data.categories, brands: res.data.brands, defValues: res.data.defValues })
          timer = setTimeout(getDatas, 30000)
        } else {
          timer = setTimeout(getDatas, 5000)
        }
      }).catch(e => {
        console.error(e)
        timer = setTimeout(getDatas, 5000)
      })
    }
    getDatas()

    return () => timer && clearTimeout(timer)
  }, [dispatch])

  return (
    <>
      <Switch>
        <Route path='/settings' component={Settings} />
        <Route path='/' component={(props) =>
          <div id='scroll-view' className="App" style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'auto' }}>
            <Header home={() => props.history.replace('/')} />
            <Switch>
              <Route path='/product-details/:id' component={ProductDetails} />
              <Route path='/' component={() => <><Container maxWidth='lg' className='main-container' style={{ flexGrow: 1, display: 'flex' }}>
                {value === 0 && <HomePage />}
                {value === 1 && <SearchPage />}
                {value === 2 && <CartPage />}
                {value === 3 && <ProfilePage />}
              </Container>
                <Container maxWidth='lg' className='main-container' style={{ position: 'sticky', bottom: 0, zIndex: 1499 }}><Footer /></Container>
                <ScrollTop /></>} />
            </Switch>
          </div>} />
      </Switch>
    </>
  );
}

const ScrollTop = () => {
  const [show, setShowing] = React.useState(false)
  const handleScroll = () => {
    document.getElementById('scroll-view').scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  React.useEffect(() => {
    const handleScroll = e => {
      setShowing(e.target.scrollTop > 100)
    }
    document.getElementById('scroll-view')?.addEventListener('scroll', handleScroll)
    return () => document.getElementById('scroll-view')?.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div style={{ position: 'fixed', bottom: 50, right: 30, display: show ? 'inherit' : 'none' }}>
      <IconButton onClick={handleScroll} style={{ background: '#ebe9e9' }}><KeyboardArrowUp /></IconButton>
    </div>
  )
}

export default App;
