import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, Button, Dialog, DialogContent, DialogActions, TextField, CircularProgress } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import axios from 'axios';
import { API } from '../Constants';

export const Brands = () => {
    const [brands, setBrands] = React.useState([])
    const [selected, setSelected] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        if (!selected) {
            setLoading(true)
            const data = new FormData();
            data.append("data", JSON.stringify({
                type: 'brands'
            }));
            axios.post(API.getData, data, {}).then(res => {
                setLoading(false)
                setBrands(res.data)
            }).catch(e => {
                setLoading(false)
            })
        }
    }, [selected])

    const handleClear = () => {
        const data = new FormData()
        data.append('table', 'brands')
        axios.post(API.clearData, data, {
        }).then((res) => {
            setLoading(true)
            const data = new FormData();
            data.append("data", JSON.stringify({
                type: 'brands'
            }));
            axios.post(API.getData, data, {}).then(res => {
                setLoading(false)
                setBrands(res.data)
            }).catch(e => {
                setLoading(false)
            })
        }).catch(e => {
            console.error(e)
        })
    }
    return (
        <>
            <Table stickyHeader size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={3} />
                        <TableCell colSpan={2} align='right'>
                            <div align='right' style={{ marginBottom: 10, zoom: 0.5 }}><Button onClick={handleClear} size='small' color='secondary' variant='contained'>Arassala</Button></div>
                            <Button onClick={() => setSelected({ name: '', image: '', idx: 0 })} size='small' variant='outlined'>+</Button>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Suraty</TableCell>
                        <TableCell>Ady</TableCell>
                        <TableCell>Tertip nomeri</TableCell>
                        <TableCell>Amal</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? <TableRow style={{ height: '100%' }}><TableCell align='center' colSpan={5}><CircularProgress size={40} /></TableCell></TableRow>
                        : brands.map(brand => <TableRow key={brand.id}>
                            <TableCell width={60}><div className='image-view' style={{ boxShadow: '0 0 2px gray', width: 60, height: 60, position: 'relative', backgroundImage: `url(${encodeURI(brand.image)})` }} /></TableCell>
                            <TableCell>{brand.name}</TableCell>
                            <TableCell align='center' width={60}>{brand.idx}</TableCell>
                            <TableCell align='center' width={60}><IconButton onClick={() => setSelected(brand)} size='small'><EditOutlined fontSize='small' /></IconButton></TableCell>
                        </TableRow>)}
                    <TableRow style={{ height: '100%' }} />
                </TableBody>
            </Table>
            {selected && <Addbrand data={selected} setData={setSelected} handleClose={() => setSelected(null)} />}
        </>
    )
}

const Addbrand = ({ data, handleClose, setData }) => {
    const handleImageUpload = e => {
        let files = e.target.files;
        if (files.length === 0) return
        const data = new FormData()
        data.append('file', files[0])
        axios.post(API.uploadImage, data, {
        }).then((res) => {
            setData(datas => ({
                ...datas,
                image: res.data.image
            }))
        }).catch(e => {
            console.error(e)
        })
    }


    const handleDelete = () => {
        const data2 = new FormData();
        data2.append("data", JSON.stringify({ data, type: 'brands', action: 'DELETE' }));
        axios.post(API.writeData, data2, {}).then(res => {
            handleClose()
        }).catch(console.error)
    }

    const handleSave = () => {
        const data2 = new FormData();
        data2.append("data", JSON.stringify({ data, type: 'brands', action: 'SAVE' }));
        axios.post(API.writeData, data2, {}).then(res => {
            handleClose()
        }).catch(console.error)
    }

    return (
        <Dialog
            open
            onClose={handleClose}>
            <DialogContent style={{ width: 350 }}>
                <div className='image-view' style={{ boxShadow: '0 0 2px gray', height: 200, width: 200, margin: 'auto', position: 'relative', backgroundImage: `url(${encodeURI(data.image)})` }}><input onChange={handleImageUpload} type='file' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} /></div>
                <div style={{ margin: 10 }}>
                    <TextField
                        fullWidth
                        label='Ady'
                        size='small'
                        onChange={e => setData(data => ({ ...data, name: e.target.value }))}
                        value={data.name}
                    />
                </div>
                <div style={{ margin: 10 }}>
                    <TextField
                        fullWidth
                        label='Tertip nomeri'
                        size='small'
                        onChange={e => setData(data => ({ ...data, idx: e.target.value }))}
                        value={data.idx}
                        type='number'
                    />
                </div>
            </DialogContent>
            <DialogActions>
                {data.id && <Button onClick={handleDelete} size='small' variant='contained' color='secondary'>Poz</Button>}
                <Button onClick={handleSave} size='small' variant='contained' color='primary'>Ýatda sakla</Button>
            </DialogActions>
        </Dialog>
    )
}