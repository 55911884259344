import React from 'react'
import { AppBar, Hidden, Toolbar, Typography, IconButton, TextField, InputAdornment, Chip } from '@mui/material';
import { Menu, Dropdown } from 'antd';
import { ArrowBackOutlined, MoreVert, PersonPinOutlined, SearchOutlined, ShoppingCartOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { CartCountBadge } from './CartCountBadge';

export const Header = ({ home }) => {
    const dispatch = useDispatch()
    const handleTab = (tab) => {
        localStorage.setItem('tab', tab)
        dispatch({ type: 'REDUCER/MAIN/TAB_INDEX', tab })
        home()
    }

    const getPopupContainerHandler = (e) => {
        return document.getElementById('ant-design') || document.body
    }

    const menu = (
        <Menu>
            <Menu.Item key={0} onClick={() => handleTab(2)} icon={<ShoppingCartOutlined />}>Sargytlar</Menu.Item>
            <Menu.Item key={1} onClick={() => handleTab(3)} icon={<PersonPinOutlined />}>Profil</Menu.Item>
        </Menu>
    )



    return (
        <AppBar position='sticky' color='transparent'>
            <Toolbar style={{ alignItems: 'center', background: '#1976d2' }} >
                <Route path='/product-details/:id' component={props => <IconButton onClick={props.history.goBack} size='small'><ArrowBackOutlined style={{ fill: 'white' }} fontSize='small' /></IconButton>} />
                <Typography variant='h6' style={{ color: 'white' }}>Owadan Foto</Typography>
                <div style={{ flexGrow: 1, minWidth: 20 }} />
                <Hidden smDown>
                    <div style={{ flexGrow: 1, minWidth: 20 }} />
                    <SearchField color='white' handleTab={handleTab} />
                    <div style={{ flexGrow: 1, minWidth: 20 }} />
                    <IconButton onClick={() => handleTab(2)}><CartCountBadge><ShoppingCartOutlined style={{ fill: 'white' }} fontSize='small' /></CartCountBadge></IconButton>
                    <IconButton onClick={() => handleTab(3)}><PersonPinOutlined style={{ fill: 'white' }} fontSize='small' /></IconButton>
                </Hidden>
                <Hidden smUp>
                    <Dropdown getPopupContainer={getPopupContainerHandler} overlay={menu} placement="bottomLeft">
                        <IconButton size='small'>
                            <MoreVert style={{ fill: 'white' }} />
                        </IconButton>
                    </Dropdown>
                </Hidden>
            </Toolbar>
            <Hidden smUp>
                <div style={{ padding: '10px 5px 5px 5px', zIndex: 1101, background: 'white' }}>
                    <SearchField color='gray' handleTab={handleTab} />
                </div>
            </Hidden>
        </AppBar>
    )
}

const SearchField = ({ color, handleTab }) => {
    const { searchText, category_1 } = useSelector(state => state.SEARCH)
    const dispatch = useDispatch()

    const handleChange = e => {
        handleTab(1)
        dispatch({ type: 'REDUCER/SEARCH/TEXT', text: e.target.value })
    }

    const handleClear = () => {
        dispatch({ type: 'REDUCER/SEARCH/CATEGORY_1', category_1: '', subCategories: [], category_2: '' })
    }

    return (
        <TextField
            fullWidth
            variant='standard'
            size='small'
            style={{ boxShadow: `0 0 2px ${color}` }}
            color='primary'
            value={searchText}
            onChange={handleChange}
            placeholder='Gözleg...'
            InputProps={{
                style: { color },
                startAdornment: <InputAdornment position='start'><IconButton size='small' onClick={() => handleTab(1)}><SearchOutlined style={{ fill: color }} fontSize='small' /></IconButton></InputAdornment>,
                endAdornment: <InputAdornment position='end'><Chip label={category_1 || 'Ählisi'} size='small' variant='filled' color='info' onDelete={Boolean(category_1) ? handleClear : null} /></InputAdornment>
            }}
        />
    )
}