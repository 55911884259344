const initialState = localStorage.getItem('cart-data-storage') ? JSON.parse(localStorage.getItem('cart-data-storage')) : {
    products: {},
    client: '',
    phone: '',
    note: '',
    status: '',
    history: []
}

export const CartReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'REDUCER/CART/CHANGE_COUNT':
            const newState = {
                ...state,
                products: {
                    ...state.products,
                    [action.id]: action.count
                }
            }
            if (action.count === 0) {
                delete newState.products[action.id]
            }
            localStorage.setItem('cart-data-storage', JSON.stringify(newState))
            return newState
        case 'REDUCER/CART/CHANGE_USER_DATA':
            return {
                ...state,
                ...action.data
            }
        case 'REDUCER/CART/CLEAR_SAVED':
            const newStateSaved = {
                products: {},
                client: '',
                phone: '',
                note: '',
                status: '',
                history: [
                    {
                        products: state.products,
                        client: state.client,
                        phone: state.phone,
                        note: state.note,
                        status: state.status,
                    },
                    ...state.history
                ]
            }
            localStorage.setItem('cart-data-storage', JSON.stringify(newStateSaved))
            return newStateSaved
        default:
            return state
    }
}